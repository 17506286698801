import { sendMessageToParent as e } from "../internal/communication.js";
import { registerHandler as s } from "../internal/handlers.js";
import { ensureInitialized as t } from "../internal/internalAPIs.js";
import { getApiVersionTag as n } from "../internal/telemetry.js";
import { getGenericOnCompleteHandler as a } from "../internal/utils.js";
import { FrameContexts as i } from "./constants.js";
import { runtime as r } from "./runtime.js";
const o = "v1";
class p {
  postMessage(s, i) {
    t(r), e(n(o, "appWindow.childAppWindow.postMessage"), "messageForChild", [s], i || a());
  }
  addEventListener(e, a) {
    t(r), "message" === e && s(n(o, "appWindow.childAppWindow.addEventListener"), "messageForParent", a);
  }
}
class m {
  static get Instance() {
    return this._instance || (this._instance = new this());
  }
  postMessage(s, p) {
    t(r, i.task), e(n(o, "appWindow.parentAppWindow.postMessage"), "messageForParent", [s], p || a());
  }
  addEventListener(e, a) {
    t(r, i.task), "message" === e && s(n(o, "appWindow.parentAppWindow.addEventListener"), "messageForChild", a);
  }
}
export { p as ChildAppWindow, m as ParentAppWindow };