import { sendAndHandleStatusAndReason as n, sendMessageToParent as o, sendAndUnwrap as t } from "../internal/communication.js";
import { registerHandler as e, removeHandler as r } from "../internal/handlers.js";
import { ensureInitialized as i } from "../internal/internalAPIs.js";
import { getApiVersionTag as s } from "../internal/telemetry.js";
import { FrameContexts as a, errorNotSupportedOnPlatform as c } from "../public/constants.js";
import { runtime as v } from "../public/runtime.js";
const l = "v1";
function d(o) {
  return new Promise(t => {
    if (i(v, a.content), !C()) throw c;
    const r = n(s(l, "conversations.openConversation"), "conversations.openConversation", {
      title: o.title,
      subEntityId: o.subEntityId,
      conversationId: o.conversationId,
      channelId: o.channelId,
      entityId: o.entityId
    });
    o.onStartConversation && e(s(l, "conversations.registerStartConversationHandler"), "startConversation", (n, t, e, r) => {
      var i;
      return null === (i = o.onStartConversation) || void 0 === i ? void 0 : i.call(o, {
        subEntityId: n,
        conversationId: t,
        channelId: e,
        entityId: r
      });
    }), o.onCloseConversation && e(s(l, "conversations.registerCloseConversationHandler"), "closeConversation", (n, t, e, r) => {
      var i;
      return null === (i = o.onCloseConversation) || void 0 === i ? void 0 : i.call(o, {
        subEntityId: n,
        conversationId: t,
        channelId: e,
        entityId: r
      });
    }), t(r);
  });
}
function m() {
  if (i(v, a.content), !C()) throw c;
  o(s(l, "conversations.closeConversation"), "conversations.closeConversation"), r("startConversation"), r("closeConversation");
}
function u() {
  return new Promise(n => {
    if (i(v), !C()) throw c;
    n(t(s(l, "conversations.getChatMember"), "getChatMembers"));
  });
}
function C() {
  return !(!i(v) || !v.supports.conversations);
}
export { m as closeConversation, u as getChatMembers, C as isSupported, d as openConversation };