import { sendMessageToParent as r } from "../../internal/communication.js";
import { ensureInitialized as e } from "../../internal/internalAPIs.js";
import { getApiVersionTag as t } from "../../internal/telemetry.js";
import { FrameContexts as n, errorNotSupportedOnPlatform as a } from "../../public/constants.js";
import { runtime as o } from "../../public/runtime.js";
import * as l from "./fullTrust/fullTrust.js";
export { l as fullTrust };
var i;
function m(l, i) {
  if (e(o, n.content), !f()) throw a;
  if (!l) throw new Error("[teams.getTeamChannels] groupId cannot be null or empty");
  if (!i) throw new Error("[teams.getTeamChannels] Callback cannot be null");
  r(t("v1", "teams.getTeamChannels"), "teams.getTeamChannels", [l], i);
}
function s(n, l) {
  if (e(o), !f()) throw a;
  if (!n) throw new Error("[teams.refreshSiteUrl] threadId cannot be null or empty");
  if (!l) throw new Error("[teams.refreshSiteUrl] Callback cannot be null");
  r(t("v1", "teams.refreshSiteUrl"), "teams.refreshSiteUrl", [n], l);
}
function f() {
  return !(!e(o) || !o.supports.teams);
}
!function (r) {
  r[r.Regular = 0] = "Regular", r[r.Private = 1] = "Private", r[r.Shared = 2] = "Shared";
}(i || (i = {}));
export { i as ChannelType, m as getTeamChannels, f as isSupported, s as refreshSiteUrl };