import { sendMessageToParent as t, callFunctionInHost as r } from "../internal/communication.js";
import { registerHandler as n, removeHandler as o } from "../internal/handlers.js";
import { ensureInitialized as i } from "../internal/internalAPIs.js";
import { getApiVersionTag as e } from "../internal/telemetry.js";
import { isNullOrUndefined as p } from "../internal/typeCheckUtilities.js";
import { ErrorCode as l } from "../public/interfaces.js";
import { runtime as s } from "../public/runtime.js";
const m = "v2";
function a(t) {
  if (!A()) throw new Error(l.NOT_SUPPORTED_ON_PLATFORM.toString());
  if (p(t)) throw new Error(l.INVALID_ARGUMENTS.toString());
  n(e(m, "otherApp.install"), "otherApp.install", t);
}
function f() {
  if (!A()) throw new Error(l.NOT_SUPPORTED_ON_PLATFORM.toString());
  t(e(m, "otherApp.unregisterInstall"), "otherApp.unregisterInstall"), o("otherApp.install");
}
function h(t) {
  if (!A()) throw new Error(l.NOT_SUPPORTED_ON_PLATFORM.toString());
  return r("otherApp.notifyInstallCompleted", [t.toString()], e(m, "otherApp.notifyInstallCompleted"));
}
function A() {
  return !(!i(s) || !s.supports.otherAppStateChange);
}
export { A as isSupported, h as notifyInstallCompleted, a as registerAppInstallationHandler, f as unregisterAppInstallationHandler };