import { sendMessageToParent as o } from "../internal/communication.js";
import { locationAPIsRequiredVersion as t } from "../internal/constants.js";
import { ensureInitialized as r, isCurrentSDKVersionAtLeast as n } from "../internal/internalAPIs.js";
import { getApiVersionTag as i } from "../internal/telemetry.js";
import { FrameContexts as a, errorNotSupportedOnPlatform as e } from "./constants.js";
import { ErrorCode as c } from "./interfaces.js";
import { runtime as l } from "./runtime.js";
function s(s, f) {
  if (!f) throw new Error("[location.getLocation] Callback cannot be null");
  if (r(l, a.content, a.task), !n(t)) throw {
    errorCode: c.OLD_PLATFORM
  };
  if (!s) throw {
    errorCode: c.INVALID_ARGUMENTS
  };
  if (!m()) throw e;
  o(i("v1", "location.getLocation"), "location.getLocation", [s], f);
}
function f(s, f) {
  if (!f) throw new Error("[location.showLocation] Callback cannot be null");
  if (r(l, a.content, a.task), !n(t)) throw {
    errorCode: c.OLD_PLATFORM
  };
  if (!s) throw {
    errorCode: c.INVALID_ARGUMENTS
  };
  if (!m()) throw e;
  o(i("v1", "location.showLocation"), "location.showLocation", [s], f);
}
function m() {
  return !(!r(l) || !l.supports.location);
}
export { s as getLocation, m as isSupported, f as showLocation };