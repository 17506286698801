import { sendAndHandleStatusAndReason as r } from "../../internal/communication.js";
import { ensureInitialized as o } from "../../internal/internalAPIs.js";
import { getApiVersionTag as t } from "../../internal/telemetry.js";
import { FrameContexts as e } from "../constants.js";
import { runtime as n } from "../runtime.js";
import * as i from "./handoff.js";
export { i as handoff };
function m(i) {
  return new Promise(m => {
    if (o(n, e.content), !l()) throw new Error("Not supported");
    if (!i.itemId || !i.itemId.trim()) throw new Error("Must supply an itemId to openMailItem");
    m(r(t("v2", "mail.openMailItem"), "mail.openMailItem", i));
  });
}
function p(i) {
  return new Promise(m => {
    if (o(n, e.content), !l()) throw new Error("Not supported");
    m(r(t("v2", "mail.composeMail"), "mail.composeMail", i));
  });
}
function l() {
  return !(!o(n) || !n.supports.mail);
}
var a;
!function (r) {
  r.New = "new", r.Reply = "reply", r.ReplyAll = "replyAll", r.Forward = "forward";
}(a || (a = {}));
export { a as ComposeMailType, p as composeMail, l as isSupported, m as openMailItem };