import { __awaiter as t } from "../../../../node_modules/.pnpm/@rollup_plugin-typescript@11.1.6_rollup@4.24.4_tslib@2.6.3_typescript@4.9.5/node_modules/tslib/tslib.es6.js";
import { callFunctionInHost as o } from "../internal/communication.js";
import { ensureInitialized as e } from "../internal/internalAPIs.js";
import { getApiVersionTag as n } from "../internal/telemetry.js";
import { AppId as i } from "../public/appId.js";
import { FrameContexts as r, errorNotSupportedOnPlatform as p } from "../public/constants.js";
import { runtime as s } from "../public/runtime.js";
const l = "v2",
  u = "Invalid store dialog size";
function c(e) {
  return t(this, void 0, void 0, function* () {
    v();
    const {
      size: t
    } = null != e ? e : {};
    return o("store.openFullStore", [b(t)], n(l, "store.openFullStore"));
  });
}
function d(e) {
  return t(this, void 0, void 0, function* () {
    v();
    const {
      size: t,
      appId: r
    } = e;
    if (!(r instanceof i)) throw new Error("No App Id present, but AppId needed to open AppDetail store");
    return o("store.openAppDetail", [b(t), r], n(l, "store.openAppDetail"));
  });
}
function f(e) {
  return t(this, void 0, void 0, function* () {
    v();
    const {
      size: t,
      appCapability: i,
      appMetaCapabilities: r,
      installationScope: p,
      filteredOutAppIds: s
    } = null != e ? e : {};
    return o("store.openInContextStore", [b(t), i, r, p, null == s ? void 0 : s.map(t => t.toString())], n(l, "store.openInContextStore"));
  });
}
function a(e) {
  return t(this, void 0, void 0, function* () {
    v();
    const {
      size: t,
      collectionId: i
    } = e;
    if (void 0 === i) throw new Error("No Collection Id present, but CollectionId needed to open a store specific to a collection");
    return o("store.openSpecificStore", [b(t), i], n(l, "store.openSpecificStore"));
  });
}
function m() {
  return e(s) && !!s.supports.store;
}
function v() {
  if (e(s, r.content, r.sidePanel, r.meetingStage), !m()) throw p;
}
function b(t) {
  if (void 0 === t) return;
  const {
    width: o,
    height: e
  } = t;
  if (void 0 !== o && "number" == typeof o && o < 0) throw new Error(u);
  if (void 0 !== e && "number" == typeof e && e < 0) throw new Error(u);
  return JSON.stringify(t);
}
export { m as isSupported, d as openAppDetail, c as openFullStore, f as openInContextStore, a as openSpecificStore };