import { __awaiter as e } from "../../../../node_modules/.pnpm/@rollup_plugin-typescript@11.1.6_rollup@4.24.4_tslib@2.6.3_typescript@4.9.5/node_modules/tslib/tslib.es6.js";
import { sendMessageToParent as r } from "../internal/communication.js";
import { registerHandler as i } from "../internal/handlers.js";
import { ensureInitialized as o } from "../internal/internalAPIs.js";
import { getApiVersionTag as t } from "../internal/telemetry.js";
import { inServerSideRenderingEnvironment as n } from "../internal/utils.js";
import { createEffectParameterChangeCallback as d, processMediaStreamWithMetadata as s, processMediaStream as a } from "../internal/videoEffectsUtils.js";
import { VideoPerformanceMonitor as f } from "../internal/videoPerformanceMonitor.js";
import { errorNotSupportedOnPlatform as m, FrameContexts as c } from "../public/constants.js";
import { runtime as l } from "../public/runtime.js";
import { isSupported as v } from "../public/videoEffects.js";
const u = "v2",
  p = 2e3,
  E = n() ? void 0 : new f(r);
var F;
function g(n) {
  var d, f;
  if (!x()) throw m;
  if (!n.videoFrameHandler || !n.videoBufferHandler) throw new Error("Both videoFrameHandler and videoBufferHandler must be provided");
  if (o(l, c.sidePanel)) {
    if (i(t(u, "videoEffectsEX.registerSetFrameProcessTimeLimitHandler"), "video.setFrameProcessTimeLimit", e => null == E ? void 0 : E.setFrameProcessTimeLimit(e), !1), null === (d = l.supports.video) || void 0 === d ? void 0 : d.mediaStream) i(t(u, "videoEffectsEX.registerStartVideoExtensibilityVideoStreamHandler"), "video.startVideoExtensibilityVideoStream", r => e(this, void 0, void 0, function* () {
      const {
          streamId: i,
          metadataInTexture: o
        } = r,
        t = E ? function (r, i) {
          return o => e(this, void 0, void 0, function* () {
            const e = o.videoFrame;
            i.reportStartFrameProcessing(e.codedWidth, e.codedHeight);
            const t = h(),
              n = yield r(o);
            return t(), i.reportFrameProcessed(), n;
          });
        }(n.videoFrameHandler, E) : n.videoFrameHandler;
      o ? yield s(i, t, y, E) : yield a(i, t, y, E);
    }), !1), r(t(u, "videoEffectsEX.mediaStream.registerForVideoFrame"), "video.mediaStream.registerForVideoFrame", [n.config]);else {
      if (!(null === (f = l.supports.video) || void 0 === f ? void 0 : f.sharedFrame)) throw m;
      i(t(u, "videoEffectsEx.registerNewVideoFrameHandler"), "video.newVideoFrame", e => {
        if (e) {
          null == E || E.reportStartFrameProcessing(e.width, e.height);
          const i = h(),
            o = e.timestamp;
          n.videoBufferHandler(function (e) {
            return e.videoFrameBuffer = e.videoFrameBuffer || e.data, delete e.data, e;
          }(e), () => {
            i(), null == E || E.reportFrameProcessed(), function (e) {
              r(t(u, "videoEffectsEx.notifyVideoFrameProcessed"), "video.videoFrameProcessed", [e]);
            }(o);
          }, y);
        }
      }, !1), r(t(u, "videoEffectsEx.registerForVideoFrame"), "video.registerForVideoFrame", [n.config]);
    }
    null == E || E.startMonitorSlowFrameProcessing();
  }
}
function h() {
  const e = setTimeout(() => {
    y(`Frame not processed in ${p}ms`, F.Warn);
  }, p);
  return function () {
    clearTimeout(e);
  };
}
function P(e, i, n) {
  if (o(l, c.sidePanel), !x()) throw m;
  r(t(u, "videoEffectsEx.notifySelectedVideoEffectChanged"), "video.videoEffectChanged", [e, i, n]);
}
function w(e) {
  if (o(l, c.sidePanel), !x()) throw m;
  i(t(u, "videoEffectsEx.registerEffectParamterChangeHandler"), "video.effectParameterChange", d(e, E), !1), r(t(u, "videoEffectsEx.registerForVideoEffect"), "video.registerForVideoEffect");
}
function V(e) {
  if (o(l, c.sidePanel), !v()) throw m;
  r(t(u, "videoEffectsEx.updatePersonalizedEffects"), "video.personalizedEffectsChanged", [e]);
}
function x() {
  return o(l), v();
}
function y(e, i = F.Warn) {
  r(t(u, "videoEffectsEx.notifyError"), "video.notifyError", [e, i]);
}
function H(e) {
  if (o(l), !v()) throw m;
  y(e, F.Fatal);
}
!function (e) {
  e.Fatal = "fatal", e.Warn = "warn";
}(F || (F = {}));
export { F as ErrorLevel, p as frameProcessingTimeoutInMs, x as isSupported, H as notifyFatalError, P as notifySelectedVideoEffectChanged, w as registerForVideoEffect, g as registerForVideoFrame, V as updatePersonalizedEffects };