import { GlobalVars as t } from "../internal/globalVars.js";
import { registerOnLoadHandler as e, registerBeforeUnloadHandler as r } from "../internal/handlers.js";
import { ensureInitialized as n } from "../internal/internalAPIs.js";
import { getApiVersionTag as i } from "../internal/telemetry.js";
import { isNullOrUndefined as o } from "../internal/typeCheckUtilities.js";
import { ssrSafeWindow as a } from "../internal/utils.js";
import { errorNotSupportedOnPlatform as s } from "./constants.js";
import { runtime as l } from "./runtime.js";
function m() {
  if (!t.printCapabilityEnabled) {
    if (n(l), !j()) throw s;
    t.printCapabilityEnabled = !0, document.addEventListener("keydown", t => {
      (t.ctrlKey || t.metaKey) && 80 === t.keyCode && (f(), t.cancelBubble = !0, t.preventDefault(), t.stopImmediatePropagation());
    });
  }
}
function f() {
  a().print();
}
function p(t) {
  d(i("v2", "teamsAPIs_registerOnLoadHandler"), t, () => {
    if (!o(t) && !j()) throw s;
  });
}
function d(t, r, i) {
  !o(r) && n(l), !o(r) && i && i(), e(t, r);
}
function u(t) {
  c(i("v2", "teamsAPIs_registerBeforeUnloadHandler"), t, () => {
    if (!o(t) && !j()) throw s;
  });
}
function c(t, e, i) {
  !o(e) && n(l), !o(e) && i && i(), r(t, e);
}
function j() {
  return !(!n(l) || !l.supports.teamsCore);
}
export { m as enablePrintCapability, j as isSupported, f as print, u as registerBeforeUnloadHandler, c as registerBeforeUnloadHandlerHelper, p as registerOnLoadHandler, d as registerOnLoadHandlerHelper };