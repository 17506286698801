import { __rest as r } from "../../../../node_modules/.pnpm/@rollup_plugin-typescript@11.1.6_rollup@4.24.4_tslib@2.6.3_typescript@4.9.5/node_modules/tslib/tslib.es6.js";
import { CartStatus as e } from "../public/marketplace.js";
import t from "../../../../node_modules/.pnpm/uuid@9.0.1/node_modules/uuid/dist/esm-browser/validate.js";
function o(r) {
  try {
    return r.cartItems = s(r.cartItems), r;
  } catch (r) {
    throw new Error("Error deserializing cart");
  }
}
function s(r) {
  return r.map(r => {
    if (r.imageURL) {
      const e = new URL(r.imageURL);
      r.imageURL = e;
    }
    return r.accessories && (r.accessories = s(r.accessories)), r;
  });
}
const i = e => {
  try {
    return e.map(e => {
      const {
          imageURL: t,
          accessories: o
        } = e,
        s = r(e, ["imageURL", "accessories"]),
        n = Object.assign({}, s);
      return t && (n.imageURL = t.href), o && (n.accessories = i(o)), n;
    });
  } catch (r) {
    throw new Error("Error serializing cart items");
  }
};
function n(r) {
  if (!Array.isArray(r) || 0 === r.length) throw new Error("cartItems must be a non-empty array");
  for (const e of r) c(e), a(e.accessories);
}
function a(r) {
  if (null != r) {
    if (!Array.isArray(r) || 0 === r.length) throw new Error("CartItem.accessories must be a non-empty array");
    for (const e of r) {
      if (e.accessories) throw new Error("Item in CartItem.accessories cannot have accessories");
      c(e);
    }
  }
}
function c(r) {
  if (!r.id) throw new Error("cartItem.id must not be empty");
  if (!r.name) throw new Error("cartItem.name must not be empty");
  u(r.price), f(r.quantity);
}
function m(r) {
  if (null != r) {
    if (!r) throw new Error("id must not be empty");
    if (!1 === t(r)) throw new Error("id must be a valid UUID");
  }
}
function u(r) {
  if ("number" != typeof r || r < 0) throw new Error(`price ${r} must be a number not less than 0`);
  if (parseFloat(r.toFixed(3)) !== r) throw new Error(`price ${r} must have at most 3 decimal places`);
}
function f(r) {
  if ("number" != typeof r || r <= 0 || parseInt(r.toString()) !== r) throw new Error(`quantity ${r} must be an integer greater than 0`);
}
function p(r) {
  if (!Object.values(e).includes(r)) throw new Error(`cartStatus ${r} is not valid`);
}
export { o as deserializeCart, s as deserializeCartItems, i as serializeCartItems, a as validateAccessoryItems, c as validateBasicCartItem, n as validateCartItems, p as validateCartStatus, u as validatePrice, f as validateQuantity, m as validateUuid };