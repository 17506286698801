import { ensureInitialized as t } from "../../internal/internalAPIs.js";
import { runtime as r } from "../../public/runtime.js";
import * as n from "./tab.js";
export { n as tab };
var o;
function i() {
  return !(!t(r) || !r.supports.hostEntity);
}
!function (t) {
  t.edu = "EDU", t.baseTownhall = "BASE_TOWNHALL", t.streamingTownhall = "STREAMING_TOWNHALL";
}(o || (o = {}));
export { o as AppTypes, i as isSupported };