import { __awaiter as t } from "../../../../../node_modules/.pnpm/@rollup_plugin-typescript@11.1.6_rollup@4.24.4_tslib@2.6.3_typescript@4.9.5/node_modules/tslib/tslib.es6.js";
import { sendAndHandleSdkError as i } from "../../internal/communication.js";
import { ensureInitialized as r } from "../../internal/internalAPIs.js";
import { getApiVersionTag as o } from "../../internal/telemetry.js";
import { FrameContexts as n, errorNotSupportedOnPlatform as e } from "../constants.js";
import { runtime as s } from "../runtime.js";
function m() {
  return t(this, void 0, void 0, function* () {
    if (r(s, n.sidePanel, n.meetingStage), !l()) throw e;
    return yield i(o("v2", "sharing.history.getContent"), "sharing.history.getContent");
  });
}
function l() {
  var t;
  return r(s) && void 0 !== (null === (t = s.supports.sharing) || void 0 === t ? void 0 : t.history);
}
export { m as getContent, l as isSupported };