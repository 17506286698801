import { sendAndHandleSdkError as n } from "../internal/communication.js";
import { ensureInitialized as t } from "../internal/internalAPIs.js";
import { getApiVersionTag as e } from "../internal/telemetry.js";
import { callCallbackWithErrorOrResultOrNullFromPromiseAndReturnPromise as o } from "../internal/utils.js";
import { FrameContexts as r, errorNotSupportedOnPlatform as i } from "./constants.js";
import { runtime as m } from "./runtime.js";
function p(p, a) {
  let c,
    u,
    f = "";
  "function" == typeof p ? (c = p, u = a, f = e("v1", "monetization.openPurchaseExperience")) : (u = p, f = e("v2", "monetization.openPurchaseExperience"));
  return t(m, r.content), o(() => new Promise(t => {
    if (!s()) throw i;
    t(n(f, "monetization.openPurchaseExperience", u));
  }), c);
}
function s() {
  return !(!t(m) || !m.supports.monetization);
}
export { s as isSupported, p as openPurchaseExperience };