import { sendMessageToParent as e } from "../internal/communication.js";
import { registerHandler as o } from "../internal/handlers.js";
import { ensureInitialized as r } from "../internal/internalAPIs.js";
import { getApiVersionTag as l } from "../internal/telemetry.js";
import { FrameContexts as t } from "../public/constants.js";
import { ErrorCode as n } from "../public/interfaces.js";
import { runtime as i } from "../public/runtime.js";
const d = "v1";
var a, s, c, f, u, g;
function S(o, n) {
  if (r(i, t.content), !o || 0 === o.length) throw new Error("[files.getCloudStorageFolders] channelId name cannot be null or empty");
  if (!n) throw new Error("[files.getCloudStorageFolders] Callback cannot be null");
  e(l(d, "files.getCloudStorageFolders"), "files.getCloudStorageFolders", [o], n);
}
function C(o, n) {
  if (r(i, t.content), !o || 0 === o.length) throw new Error("[files.addCloudStorageFolder] channelId name cannot be null or empty");
  if (!n) throw new Error("[files.addCloudStorageFolder] Callback cannot be null");
  e(l(d, "files.addCloudStorageFolder"), "files.addCloudStorageFolder", [o], n);
}
function w(o, n, a) {
  if (r(i, t.content), !o) throw new Error("[files.deleteCloudStorageFolder] channelId name cannot be null or empty");
  if (!n) throw new Error("[files.deleteCloudStorageFolder] folderToDelete cannot be null or empty");
  if (!a) throw new Error("[files.deleteCloudStorageFolder] Callback cannot be null");
  e(l(d, "files.deleteCloudStorageFolder"), "files.deleteCloudStorageFolder", [o, n], a);
}
function v(o, n, a) {
  if (r(i, t.content), !o || !n) throw new Error("[files.getCloudStorageFolderContents] folder/providerCode name cannot be null or empty");
  if (!a) throw new Error("[files.getCloudStorageFolderContents] Callback cannot be null");
  if ("isSubdirectory" in o && !o.isSubdirectory) throw new Error("[files.getCloudStorageFolderContents] provided folder is not a subDirectory");
  e(l(d, "files.getCloudStorageFolderContents"), "files.getCloudStorageFolderContents", [o, n], a);
}
function F(o, n, a) {
  if (r(i, t.content), !o || !n) throw new Error("[files.openCloudStorageFile] file/providerCode cannot be null or empty");
  if (o.isSubdirectory) throw new Error("[files.openCloudStorageFile] provided file is a subDirectory");
  e(l(d, "files.openCloudStorageFile"), "files.openCloudStorageFile", [o, n, a]);
}
function h(o = !1, n) {
  if (r(i, t.content), !n) throw new Error("[files.getExternalProviders] Callback cannot be null");
  e(l(d, "files.getExternalProviders"), "files.getExternalProviders", [o], n);
}
function p(o, n, a, s, c = !1, f) {
  if (r(i, t.content), !o || 0 === o.length) throw new Error("[files.copyMoveFiles] selectedFiles cannot be null or empty");
  if (!n) throw new Error("[files.copyMoveFiles] providerCode cannot be null or empty");
  if (!a) throw new Error("[files.copyMoveFiles] destinationFolder cannot be null or empty");
  if (!s) throw new Error("[files.copyMoveFiles] destinationProviderCode cannot be null or empty");
  if (!f) throw new Error("[files.copyMoveFiles] callback cannot be null");
  e(l(d, "files.copyMoveFiles"), "files.copyMoveFiles", [o, n, a, s, c], f);
}
function m(o) {
  if (r(i, t.content), !o) throw new Error("[files.getFileDownloads] Callback cannot be null");
  e(l(d, "files.getFileDownloads"), "files.getFileDownloads", [], o);
}
function b(o = void 0, n) {
  if (r(i, t.content), !n) throw new Error("[files.openDownloadFolder] Callback cannot be null");
  e(l(d, "files.openDownloadFolder"), "files.openDownloadFolder", [o], n);
}
function E(o) {
  if (r(i, t.content), !o) throw G(n.INVALID_ARGUMENTS, "[files.addCloudStorageProvider] callback cannot be null");
  e(l(d, "files.addCloudStorageProvider"), "files.addCloudStorageProvider", [], o);
}
function P(o, a) {
  if (r(i, t.content), !a) throw G(n.INVALID_ARGUMENTS, "[files.removeCloudStorageProvider] callback cannot be null");
  if (!o || !o.content) throw G(n.INVALID_ARGUMENTS, "[files.removeCloudStorageProvider] 3P cloud storage provider request content is missing");
  e(l(d, "files.removeCloudStorageProvider"), "files.removeCloudStorageProvider", [o], a);
}
function D(o, a) {
  if (r(i, t.content), !a) throw G(n.INVALID_ARGUMENTS, "[files.addCloudStorageProviderFile] callback cannot be null");
  if (!o || !o.content) throw G(n.INVALID_ARGUMENTS, "[files.addCloudStorageProviderFile] 3P cloud storage provider request content is missing");
  e(l(d, "files.addCloudStorageProviderFile"), "files.addCloudStorageProviderFile", [o], a);
}
function I(o, a) {
  if (r(i, t.content), !a) throw G(n.INVALID_ARGUMENTS, "[files.renameCloudStorageProviderFile] callback cannot be null");
  if (!o || !o.content) throw G(n.INVALID_ARGUMENTS, "[files.renameCloudStorageProviderFile] 3P cloud storage provider request content is missing");
  e(l(d, "files.renameCloudStorageProviderFile"), "files.renameCloudStorageProviderFile", [o], a);
}
function A(o, a) {
  if (r(i, t.content), !a) throw G(n.INVALID_ARGUMENTS, "[files.deleteCloudStorageProviderFile] callback cannot be null");
  if (!(o && o.content && o.content.itemList && o.content.itemList.length > 0)) throw G(n.INVALID_ARGUMENTS, "[files.deleteCloudStorageProviderFile] 3P cloud storage provider request content details are missing");
  e(l(d, "files.deleteCloudStorageProviderFile"), "files.deleteCloudStorageProviderFile", [o], a);
}
function N(o, a) {
  if (r(i, t.content), !a) throw G(n.INVALID_ARGUMENTS, "[files.downloadCloudStorageProviderFile] callback cannot be null");
  if (!(o && o.content && o.content.itemList && o.content.itemList.length > 0)) throw G(n.INVALID_ARGUMENTS, "[files.downloadCloudStorageProviderFile] 3P cloud storage provider request content details are missing");
  e(l(d, "files.downloadCloudStorageProviderFile"), "files.downloadCloudStorageProviderFile", [o], a);
}
function y(o, a) {
  if (r(i, t.content), !a) throw G(n.INVALID_ARGUMENTS, "[files.uploadCloudStorageProviderFile] callback cannot be null");
  if (!(o && o.content && o.content.itemList && o.content.itemList.length > 0)) throw G(n.INVALID_ARGUMENTS, "[files.uploadCloudStorageProviderFile] 3P cloud storage provider request content details are missing");
  if (!o.content.destinationFolder) throw G(n.INVALID_ARGUMENTS, "[files.uploadCloudStorageProviderFile] Invalid destination folder details");
  e(l(d, "files.uploadCloudStorageProviderFile"), "files.uploadCloudStorageProviderFile", [o], a);
}
function L(e) {
  if (r(i), !e) throw new Error("[registerCloudStorageProviderListChangeHandler] Handler cannot be null");
  o(l(d, "files.registerCloudStorageProviderListChangeHandler"), "files.cloudStorageProviderListChange", e);
}
function M(e) {
  if (r(i), !e) throw new Error("[registerCloudStorageProviderContentChangeHandler] Handler cannot be null");
  o(l(d, "files.registerCloudStorageProviderContentChangeHandler"), "files.cloudStorageProviderContentChange", e);
}
function G(e, o) {
  return {
    errorCode: e,
    message: o
  };
}
!function (e) {
  e.Dropbox = "DROPBOX", e.Box = "BOX", e.Sharefile = "SHAREFILE", e.GoogleDrive = "GOOGLEDRIVE", e.Egnyte = "EGNYTE", e.SharePoint = "SharePoint";
}(a || (a = {})), function (e) {
  e[e.Sharepoint = 0] = "Sharepoint", e[e.WopiIntegration = 1] = "WopiIntegration", e[e.Google = 2] = "Google", e[e.OneDrive = 3] = "OneDrive", e[e.Recent = 4] = "Recent", e[e.Aggregate = 5] = "Aggregate", e[e.FileSystem = 6] = "FileSystem", e[e.Search = 7] = "Search", e[e.AllFiles = 8] = "AllFiles", e[e.SharedWithMe = 9] = "SharedWithMe";
}(s || (s = {})), function (e) {
  e.ClassMaterials = "classMaterials";
}(c || (c = {})), function (e) {
  e.Readonly = "readonly";
}(f || (f = {})), function (e) {
  e.Downloaded = "Downloaded", e.Downloading = "Downloading", e.Failed = "Failed";
}(u || (u = {})), function (e) {
  e.Download = "DOWNLOAD", e.Upload = "UPLOAD", e.Delete = "DELETE";
}(g || (g = {}));
export { a as CloudStorageProvider, g as CloudStorageProviderFileAction, s as CloudStorageProviderType, f as DocumentLibraryAccessType, u as FileDownloadStatus, c as SpecialDocumentLibraryType, C as addCloudStorageFolder, E as addCloudStorageProvider, D as addCloudStorageProviderFile, p as copyMoveFiles, w as deleteCloudStorageFolder, A as deleteCloudStorageProviderFile, N as downloadCloudStorageProviderFile, v as getCloudStorageFolderContents, S as getCloudStorageFolders, h as getExternalProviders, m as getFileDownloads, F as openCloudStorageFile, b as openDownloadFolder, M as registerCloudStorageProviderContentChangeHandler, L as registerCloudStorageProviderListChangeHandler, P as removeCloudStorageProvider, I as renameCloudStorageProviderFile, y as uploadCloudStorageProviderFile };