import { shouldEventBeRelayedToChild as e, sendMessageEventToChild as t } from "../internal/childCommunication.js";
import { sendMessageToParent as n } from "../internal/communication.js";
import { registerHandler as i } from "../internal/handlers.js";
import { ensureInitialized as r } from "../internal/internalAPIs.js";
import { getApiVersionTag as o } from "../internal/telemetry.js";
import { getGenericOnCompleteHandler as s } from "../internal/utils.js";
import { FrameContexts as l } from "../public/constants.js";
import { runtime as m } from "../public/runtime.js";
const a = "v1";
function p(e, t) {
  r(m), n(o(a, "uploadCustomApp"), "uploadCustomApp", [e], t || s());
}
function c(e, t, i) {
  r(m), n(o(a, "sendCustomMessage"), e, t, i);
}
function u(n, i) {
  if (r(m), !e()) throw new Error("The child window has not yet been initialized or is not present");
  t(n, i);
}
function d(e, t) {
  r(m), i(o(a, "registerCustomHandler"), e, (...e) => t.apply(this, e));
}
function f(e, t) {
  r(m), i(o(a, "registerUserSettingsChangeHandler"), "userSettingsChange", t, !0, [e]);
}
function w(e) {
  r(m, l.content, l.task);
  const t = [e.entityId, e.title, e.description, e.type, e.objectUrl, e.downloadUrl, e.webPreviewUrl, e.webEditUrl, e.baseUrl, e.editFile, e.subEntityId, e.viewerAction, e.fileOpenPreference, e.conversationId, e.sizeInBytes];
  n(o(a, "openFilePreview"), "openFilePreview", t);
}
export { w as openFilePreview, d as registerCustomHandler, f as registerUserSettingsChangeHandler, u as sendCustomEvent, c as sendCustomMessage, p as uploadCustomApp };