import { sendAndUnwrap as t } from "../../../internal/communication.js";
import { getUserJoinedTeamsSupportedAndroidClientVersion as r } from "../../../internal/constants.js";
import { GlobalVars as e } from "../../../internal/globalVars.js";
import { ensureInitialized as s, isCurrentSDKVersionAtLeast as o } from "../../../internal/internalAPIs.js";
import { getApiVersionTag as n } from "../../../internal/telemetry.js";
import { errorNotSupportedOnPlatform as i, HostClientType as m } from "../../../public/constants.js";
import { ErrorCode as a } from "../../../public/interfaces.js";
import { runtime as l } from "../../../public/runtime.js";
function p(p) {
  return new Promise(f => {
    if (s(l), !u()) throw i;
    if ((e.hostClientType === m.android || e.hostClientType === m.teamsRoomsAndroid || e.hostClientType === m.teamsPhones || e.hostClientType === m.teamsDisplays) && !o(r)) {
      const t = {
        errorCode: a.OLD_PLATFORM
      };
      throw new Error(JSON.stringify(t));
    }
    f(t(n("v1", "teams.fullTrust.joinedTeams.getUserJoinedTeams"), "getUserJoinedTeams", p));
  });
}
function u() {
  return !(!s(l) || !l.supports.teams) && !!l.supports.teams.fullTrust && !!l.supports.teams.fullTrust.joinedTeams;
}
export { p as getUserJoinedTeams, u as isSupported };