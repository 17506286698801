import { __awaiter as i } from "../../../../node_modules/.pnpm/@rollup_plugin-typescript@11.1.6_rollup@4.24.4_tslib@2.6.3_typescript@4.9.5/node_modules/tslib/tslib.es6.js";
import { ORIGIN_LIST_FETCH_TIMEOUT_IN_MS as t, validOriginsCdnEndpoint as r, validOriginsFallback as n } from "./constants.js";
import { GlobalVars as e } from "./globalVars.js";
import { getLogger as o } from "./telemetry.js";
import { inServerSideRenderingEnvironment as l, isValidHttpsURL as s } from "./utils.js";
let a = [];
const u = o("validateOrigin");
function c() {
  return i(this, void 0, void 0, function* () {
    yield d();
  });
}
function d(e = !1) {
  return i(this, void 0, void 0, function* () {
    if (0 !== a.length && !e) return a;
    if (l()) return a = n, n;
    {
      u("Initiating fetch call to acquire valid origins list from CDN");
      const i = new AbortController(),
        e = setTimeout(() => i.abort(), t);
      return fetch(r, {
        signal: i.signal
      }).then(i => {
        if (clearTimeout(e), !i.ok) throw new Error("Invalid Response from Fetch Call");
        return u("Fetch call completed and retrieved valid origins list from CDN"), i.json().then(i => {
          if (function (i) {
            let t = JSON.parse(i);
            try {
              t = JSON.parse(i);
            } catch (i) {
              return !1;
            }
            if (!t.validOrigins) return !1;
            for (const i of t.validOrigins) try {
              new URL("https://" + i);
            } catch (t) {
              return u("isValidOriginsFromCDN call failed to validate origin: %s", i), !1;
            }
            return !0;
          }(JSON.stringify(i))) return a = i.validOrigins, a;
          throw new Error("Valid origins list retrieved from CDN is invalid");
        });
      }).catch(i => ("AbortError" === i.name ? u(`validOrigins fetch call to CDN failed due to Timeout of ${t} ms. Defaulting to fallback list`) : u("validOrigins fetch call to CDN failed with error: %s. Defaulting to fallback list", i), a = n, a));
    }
  });
}
function f(i, t) {
  if ("*." === i.substring(0, 2)) {
    const r = i.substring(1);
    if (t.length > r.length && t.split(".").length === r.split(".").length && t.substring(t.length - r.length) === r) return !0;
  } else if (i === t) return !0;
  return !1;
}
function g(i, t) {
  return d(t).then(t => {
    if (!s(i)) return u("Origin %s is invalid because it is not using https protocol. Protocol being used: %s", i, i.protocol), !1;
    const r = i.host;
    if (t.some(i => f(i, r))) return !0;
    for (const i of e.additionalValidOrigins) {
      if (f("https://" === i.substring(0, 8) ? i.substring(8) : i, r)) return !0;
    }
    return u("Origin %s is invalid because it is not an origin approved by this library or included in the call to app.initialize.\nOrigins approved by this library: %o\nOrigins included in app.initialize: %o", i, t, e.additionalValidOrigins), !1;
  });
}
c();
export { c as prefetchOriginsFromCDN, g as validateOrigin };