import { __awaiter as n } from "../../../../node_modules/.pnpm/@rollup_plugin-typescript@11.1.6_rollup@4.24.4_tslib@2.6.3_typescript@4.9.5/node_modules/tslib/tslib.es6.js";
import { flushMessageQueue as i, getMessageIdsAsLogString as o } from "./communicationUtils.js";
import { callHandler as s } from "./handlers.js";
import { deserializeMessageRequest as e, serializeMessageResponse as r } from "./messageObjects.js";
import { getLogger as t, getApiVersionTag as d } from "./telemetry.js";
const u = t("childProxyingCommunication");
class a {}
function c() {
  a.window = null, a.origin = null, a.messageQueue = [];
}
function l() {
  return !!a.window;
}
function g(n, i) {
  return a.window && !a.window.closed && n !== a.window || (a.window = n, a.origin = i), a.window && a.window.closed ? (a.window = null, a.origin = null, !1) : a.window === n;
}
function m(r, t, u, c) {
  return n(this, void 0, void 0, function* () {
    a.window === t && (i(a.window, a.origin, a.messageQueue, "child"), function (n, i, r) {
      if (void 0 === n.data.id || void 0 === n.data.func) return;
      const t = e(n.data),
        [u, c] = s(t.func, t.args);
      if (u && void 0 !== c) return w("Handler called in response to message %s from child. Returning response from handler to child, action: %s.", o(t), t.func), void f(t.id, t.uuid, Array.isArray(c) ? c : [c]);
      w("No handler for message %s from child found; relaying message on to parent, action: %s. Relayed message will have a new id.", o(t), t.func), function (n, i, s) {
        const e = i(d("v2", "tasks.startTask"), n.func, n.args, !0);
        s(e.uuid, (...i) => {
          if (a.window) {
            const s = i.pop();
            w("Message from parent being relayed to child, id: %s", o(n)), f(n.id, n.uuid, i, s);
          }
        });
      }(t, i, r);
    }(r, u, c));
  });
}
a.messageQueue = [];
const w = u.extend("handleIncomingMessageFromChild");
function f(n, i, s, e) {
  const t = a.window,
    d = function (n, i, o, s) {
      return {
        id: n,
        uuid: i,
        args: o || [],
        isPartialResponse: s
      };
    }(n, i, s, e),
    u = r(d),
    c = a.origin;
  t && c && (w("Sending message %s to %s via postMessage, args = %o", o(u), "child", u.args), t.postMessage(u, c));
}
function p(n, i) {
  const o = a.window,
    s = function (n, i) {
      return {
        func: n,
        args: i || []
      };
    }(n, i),
    e = a.origin;
  o && e ? o.postMessage(s, e) : a.messageQueue.push(s);
}
export { m as handleIncomingMessageFromChild, p as sendMessageEventToChild, l as shouldEventBeRelayedToChild, g as shouldProcessChildMessage, c as uninitializeChildCommunication };