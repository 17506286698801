import { sendAndHandleSdkError as e } from "../../internal/communication.js";
import { ensureInitialized as t } from "../../internal/internalAPIs.js";
import { getApiVersionTag as n } from "../../internal/telemetry.js";
import { callCallbackWithSdkErrorFromPromiseAndReturnPromise as r } from "../../internal/utils.js";
import { FrameContexts as o, errorNotSupportedOnPlatform as s } from "../constants.js";
import { ErrorCode as i } from "../interfaces.js";
import { runtime as m } from "../runtime.js";
import * as a from "./history.js";
export { a as history };
const c = {
  shareWebContent: "sharing.shareWebContent"
};
function f(e, s) {
  try {
    !function (e) {
      if (!(e && e.content && e.content.length)) {
        throw {
          errorCode: i.INVALID_ARGUMENTS,
          message: "Shared content is missing"
        };
      }
    }(e), function (e) {
      let t;
      if (e.content.some(e => !e.type)) throw t = {
        errorCode: i.INVALID_ARGUMENTS,
        message: "Shared content type cannot be undefined"
      }, t;
      if (e.content.some(t => t.type !== e.content[0].type)) throw t = {
        errorCode: i.INVALID_ARGUMENTS,
        message: "Shared content must be of the same type"
      }, t;
    }(e), function (e) {
      let t;
      if ("URL" !== e.content[0].type) throw t = {
        errorCode: i.INVALID_ARGUMENTS,
        message: "Content type is unsupported"
      }, t;
      if (e.content.some(e => !e.url)) throw t = {
        errorCode: i.INVALID_ARGUMENTS,
        message: "URLs are required for URL content types"
      }, t;
    }(e);
  } catch (e) {
    return r(() => Promise.reject(e), s);
  }
  t(m, o.content, o.sidePanel, o.task, o.stage, o.meetingStage);
  const a = n(s ? "v1" : "v2", "sharing.shareWebContent");
  return r(p, s, a, e);
}
function p(t, n) {
  return new Promise(r => {
    if (!h()) throw s;
    r(e(t, c.shareWebContent, n));
  });
}
function h() {
  return !(!t(m) || !m.supports.sharing);
}
export { c as SharingAPIMessages, h as isSupported, f as shareWebContent };