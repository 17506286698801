import { appInitializeHelper as e, notifyAppLoadedHelper as i, notifySuccessHelper as t, notifyFailureHelper as n, notifyExpectedFailureHelper as a, registerOnThemeChangeHandlerHelper as o, openLinkHelper as s } from "../../internal/appHelpers.js";
import { uninitializeCommunication as r, sendAndUnwrap as l, Communication as m } from "../../internal/communication.js";
import { GlobalVars as d } from "../../internal/globalVars.js";
import { uninitializeHandlers as p, registerHostToAppPerformanceMetricsHandler as c } from "../../internal/handlers.js";
import { ensureInitializeCalled as u } from "../../internal/internalAPIs.js";
import { getLogger as f, getApiVersionTag as h } from "../../internal/telemetry.js";
import { inServerSideRenderingEnvironment as I } from "../../internal/utils.js";
import { AppId as g } from "../appId.js";
import { HostName as y, HostClientType as S } from "../constants.js";
import { version as T } from "../version.js";
import * as C from "./lifecycle.js";
export { C as lifecycle };
import { _clearTelemetryPort as v } from "../../private/messageChannels/telemetry.js";
import { _clearDataLayerPort as P } from "../../private/messageChannels/dataLayer.js";
const j = "v2",
  w = f("app"),
  N = {
    AppLoaded: "appInitialization.appLoaded",
    Success: "appInitialization.success",
    Failure: "appInitialization.failure",
    ExpectedFailure: "appInitialization.expectedFailure"
  };
var O, b;
function F() {
  return d.initializeCompleted;
}
function x() {
  return d.frameContext;
}
function L(i) {
  return e(h(j, "app.initialize"), i);
}
function A(e) {
  m.currentWindow = e;
}
function z() {
  d.initializeCalled && (p(), d.initializeCalled = !1, d.initializeCompleted = !1, d.initializePromise = void 0, d.additionalValidOrigins = [], d.frameContext = void 0, d.hostClientType = void 0, d.isFramelessWindow = !1, v(), P(), r());
}
function D() {
  return new Promise(e => {
    u(), e(l(h(j, "app.getContext"), "getContext"));
  }).then(e => function (e) {
    var i;
    const t = {
      actionInfo: e.actionInfo,
      app: {
        locale: e.locale,
        sessionId: e.appSessionId ? e.appSessionId : "",
        theme: e.theme ? e.theme : "default",
        iconPositionVertical: e.appIconPosition,
        osLocaleInfo: e.osLocaleInfo,
        parentMessageId: e.parentMessageId,
        userClickTime: e.userClickTime,
        userClickTimeV2: e.userClickTimeV2,
        userFileOpenPreference: e.userFileOpenPreference,
        host: {
          name: e.hostName ? e.hostName : y.teams,
          clientType: e.hostClientType ? e.hostClientType : S.web,
          sessionId: e.sessionId ? e.sessionId : "",
          ringId: e.ringId
        },
        appLaunchId: e.appLaunchId,
        appId: e.appId ? new g(e.appId) : void 0,
        manifestVersion: e.manifestVersion
      },
      page: {
        id: e.entityId,
        frameContext: e.frameContext ? e.frameContext : d.frameContext,
        subPageId: e.subEntityId,
        isFullScreen: e.isFullScreen,
        isMultiWindow: e.isMultiWindow,
        isBackgroundLoad: e.isBackgroundLoad,
        sourceOrigin: e.sourceOrigin
      },
      user: {
        id: null !== (i = e.userObjectId) && void 0 !== i ? i : "",
        displayName: e.userDisplayName,
        isCallingAllowed: e.isCallingAllowed,
        isPSTNCallingAllowed: e.isPSTNCallingAllowed,
        licenseType: e.userLicenseType,
        loginHint: e.loginHint,
        userPrincipalName: e.userPrincipalName,
        tenant: e.tid ? {
          id: e.tid,
          teamsSku: e.tenantSKU
        } : void 0
      },
      channel: e.channelId ? {
        id: e.channelId,
        displayName: e.channelName,
        relativeUrl: e.channelRelativeUrl,
        membershipType: e.channelType,
        defaultOneNoteSectionId: e.defaultOneNoteSectionId,
        ownerGroupId: e.hostTeamGroupId,
        ownerTenantId: e.hostTeamTenantId
      } : void 0,
      chat: e.chatId ? {
        id: e.chatId
      } : void 0,
      meeting: e.meetingId ? {
        id: e.meetingId
      } : void 0,
      sharepoint: e.sharepoint,
      team: e.teamId ? {
        internalId: e.teamId,
        displayName: e.teamName,
        type: e.teamType,
        groupId: e.groupId,
        templateId: e.teamTemplateId,
        isArchived: e.isTeamArchived,
        userRole: e.userTeamRole
      } : void 0,
      sharePointSite: e.teamSiteUrl || e.teamSiteDomain || e.teamSitePath || e.mySitePath || e.mySiteDomain ? {
        teamSiteUrl: e.teamSiteUrl,
        teamSiteDomain: e.teamSiteDomain,
        teamSitePath: e.teamSitePath,
        teamSiteId: e.teamSiteId,
        mySitePath: e.mySitePath,
        mySiteDomain: e.mySiteDomain
      } : void 0,
      dialogParameters: e.dialogParameters || {}
    };
    return t;
  }(e));
}
function k() {
  u(), i(h(j, "app.notifyAppLoaded"));
}
function U() {
  return t(h(j, "app.notifySuccess"));
}
function V(e) {
  u(), n(h(j, "app.notifyFailure"), e);
}
function E(e) {
  u(), a(h(j, "app.notifyExpectedFailure"), e);
}
function H(e) {
  o(h(j, "app.registerOnThemeChangeHandler"), e);
}
function M(e) {
  c(e);
}
function W(e) {
  return s(h(j, "app.openLink"), e);
}
!function (e) {
  e.AuthFailed = "AuthFailed", e.Timeout = "Timeout", e.Other = "Other";
}(O || (O = {})), function (e) {
  e.PermissionError = "PermissionError", e.NotFound = "NotFound", e.Throttling = "Throttling", e.Offline = "Offline", e.Other = "Other";
}(b || (b = {})), w("teamsjs instance is version %s, starting at %s UTC (%s local)", T, new Date().toISOString(), new Date().toLocaleString()), function () {
  if (I()) return;
  const e = document.getElementsByTagName("script"),
    i = e && e[e.length - 1] && e[e.length - 1].src,
    t = "Today, teamsjs can only be used from a single script or you may see undefined behavior. This log line is used to help detect cases where teamsjs is loaded multiple times -- it is always written. The presence of the log itself does not indicate a multi-load situation, but multiples of these log lines will. If you would like to use teamjs from more than one script at the same time, please open an issue at https://github.com/OfficeDev/microsoft-teams-library-js/issues";
  i && 0 !== i.length ? w("teamsjs is being used from %s. %s", i, t) : w("teamsjs is being used from a script tag embedded directly in your html. %s", t);
}();
export { b as ExpectedFailureReason, O as FailedReason, N as Messages, A as _initialize, z as _uninitialize, D as getContext, x as getFrameContext, L as initialize, F as isInitialized, k as notifyAppLoaded, E as notifyExpectedFailure, V as notifyFailure, U as notifySuccess, W as openLink, M as registerHostToAppPerformanceMetricsHandler, H as registerOnThemeChangeHandler };