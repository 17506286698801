import { sendMessageToParent as t } from "../internal/communication.js";
import { ensureInitialized as n } from "../internal/internalAPIs.js";
import { getApiVersionTag as r } from "../internal/telemetry.js";
import { FrameContexts as p, errorNotSupportedOnPlatform as i } from "../public/constants.js";
import { runtime as e } from "../public/runtime.js";
function o(o, a, m, c) {
  if (n(e, p.content), !l()) throw i;
  if (!o || 0 == o.length) throw new Error("[appEntity.selectAppEntity] threadId name cannot be null or empty");
  if (!c) throw new Error("[appEntity.selectAppEntity] Callback cannot be null");
  t(r("v1", "appEntity.selectAppEntity"), "appEntity.selectAppEntity", [o, a, m], c);
}
function l() {
  return !(!n(e) || !e.supports.appEntity);
}
export { l as isSupported, o as selectAppEntity };