import { sendMessageToParentAsync as n } from "../internal/communication.js";
import { ensureInitialized as t } from "../internal/internalAPIs.js";
import { getApiVersionTag as e } from "../internal/telemetry.js";
import { FrameContexts as r, errorNotSupportedOnPlatform as i } from "../public/constants.js";
import { runtime as o } from "../public/runtime.js";
import { AppId as p } from "../public/appId.js";
var c, s;
function a(c, s) {
  if (t(o, r.content), !A()) throw i;
  const a = new p(c);
  return n(e("v2", "externalAppCardActions.processActionSubmit"), "externalAppCardActions.processActionSubmit", [a.toString(), s]).then(([n, t]) => {
    if (!n) throw t;
  });
}
function l(c, s, a) {
  if (t(o, r.content), !A()) throw i;
  const l = new p(c);
  return n(e("v2", "externalAppCardActions.processActionOpenUrl"), "externalAppCardActions.processActionOpenUrl", [l.toString(), s.href, a]).then(([n, t]) => {
    if (n) throw n;
    return t;
  });
}
function A() {
  return !(!t(o) || !o.supports.externalAppCardActions);
}
!function (n) {
  n.DeepLinkDialog = "DeepLinkDialog", n.DeepLinkOther = "DeepLinkOther", n.DeepLinkStageView = "DeepLinkStageView", n.GenericUrl = "GenericUrl";
}(c || (c = {})), function (n) {
  n.INTERNAL_ERROR = "INTERNAL_ERROR", n.INVALID_LINK = "INVALID_LINK", n.NOT_SUPPORTED = "NOT_SUPPORTED";
}(s || (s = {}));
export { s as ActionOpenUrlErrorCode, c as ActionOpenUrlType, A as isSupported, l as processActionOpenUrl, a as processActionSubmit };