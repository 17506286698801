import * as t from "../artifactsForCDN/validDomains.json.js";
const i = "2.0.1",
  s = "2.0.2",
  e = "2.0.3",
  n = "2.0.4",
  o = "2.0.1",
  a = "1.9.0",
  r = "2.0.0",
  m = "1.7.0",
  l = "1.8.0",
  d = "2.0.0",
  h = "1.9.0",
  p = t.validOrigins,
  c = 1500,
  f = new URL("https://res.cdn.office.net/teams-js/validDomains/json/validDomains.json"),
  j = /^https:\/\//,
  v = "https",
  y = "teams.microsoft.com",
  D = "The library has not yet been initialized",
  T = "The runtime has not yet been initialized",
  b = "The runtime version is not supported",
  u = "The call was not properly started";
export { c as ORIGIN_LIST_FETCH_TIMEOUT_IN_MS, m as captureImageMobileSupportVersion, i as defaultSDKVersionForCompatCheck, u as errorCallNotStarted, D as errorLibraryNotInitialized, T as errorRuntimeNotInitialized, b as errorRuntimeNotSupported, d as getMediaCallbackSupportVersion, o as getUserJoinedTeamsSupportedAndroidClientVersion, n as imageOutputFormatsAPISupportVersion, a as locationAPIsRequiredVersion, l as mediaAPISupportVersion, e as nonFullScreenVideoModeAPISupportVersion, r as peoplePickerRequiredVersion, h as scanBarCodeAPIMobileSupportVersion, y as teamsDeepLinkHost, v as teamsDeepLinkProtocol, j as userOriginUrlValidationRegExp, f as validOriginsCdnEndpoint, p as validOriginsFallback, s as videoAndImageMediaAPISupportVersion };