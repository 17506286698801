import { __awaiter as e, __rest as i } from "../../../../node_modules/.pnpm/@rollup_plugin-typescript@11.1.6_rollup@4.24.4_tslib@2.6.3_typescript@4.9.5/node_modules/tslib/tslib.es6.js";
import { sendMessageToParent as r } from "../internal/communication.js";
import { registerHandler as o } from "../internal/handlers.js";
import { ensureInitialized as t } from "../internal/internalAPIs.js";
import { getApiVersionTag as n } from "../internal/telemetry.js";
import { ssrSafeWindow as d, inServerSideRenderingEnvironment as s } from "../internal/utils.js";
import { createEffectParameterChangeCallback as a, processMediaStream as f } from "../internal/videoEffectsUtils.js";
import { VideoPerformanceMonitor as m } from "../internal/videoPerformanceMonitor.js";
import { FrameContexts as l, errorNotSupportedOnPlatform as c } from "./constants.js";
import { runtime as v } from "./runtime.js";
const u = "v2",
  p = s() ? void 0 : new m(r);
var F, E, g;
function h(d) {
  if (t(v, l.sidePanel), !b()) throw c;
  if (!d.videoFrameHandler || !d.videoBufferHandler) throw new Error("Both videoFrameHandler and videoBufferHandler must be provided");
  if (o(n(u, "videoEffects.setFrameProcessTimeLimitHandler"), "video.setFrameProcessTimeLimit", e => null == p ? void 0 : p.setFrameProcessTimeLimit(e.timeLimit), !1), j()) !function (i, d) {
    if (t(v, l.sidePanel), !b() || !j()) throw c;
    o(n(u, "videoEffects.startVideoExtensibilityVideoStreamHandler"), "video.startVideoExtensibilityVideoStream", r => e(this, void 0, void 0, function* () {
      const {
          streamId: o
        } = r,
        t = function (i, r) {
          return o => e(this, void 0, void 0, function* () {
            const e = o.videoFrame;
            null == r || r.reportStartFrameProcessing(e.codedWidth, e.codedHeight);
            const t = yield i(o);
            return null == r || r.reportFrameProcessed(), t;
          });
        }(i, p);
      yield f(o, t, V, p);
    }), !1), r(n(u, "videoEffects.mediaStream.registerForVideoFrame"), "video.mediaStream.registerForVideoFrame", [d]);
  }(d.videoFrameHandler, d.config);else {
    if (!S()) throw c;
    !function (e, d) {
      if (t(v, l.sidePanel), !b() || !S()) throw c;
      o(n(u, "videoEffects.registerForVideoBufferHandler"), "video.newVideoFrame", o => {
        if (o) {
          const t = o.timestamp;
          null == p || p.reportStartFrameProcessing(o.width, o.height), e(function (e) {
            if ("videoFrameBuffer" in e) return e;
            {
              const {
                  data: r
                } = e,
                o = i(e, ["data"]);
              return Object.assign(Object.assign({}, o), {
                videoFrameBuffer: r
              });
            }
          }(o), () => {
            null == p || p.reportFrameProcessed(), function (e) {
              r(n(u, "videoEffects.notifyVideoFrameProcessed"), "video.videoFrameProcessed", [e]);
            }(t);
          }, V);
        }
      }, !1), r(n(u, "videoEffects.registerForVideoFrame"), "video.registerForVideoFrame", [d]);
    }(d.videoBufferHandler, d.config);
  }
  null == p || p.startMonitorSlowFrameProcessing();
}
function P(e, i) {
  if (t(v, l.sidePanel), !b()) throw c;
  r(n(u, "videoEffects.notifySelectedVideoEffectChanged"), "video.videoEffectChanged", [e, i]);
}
function w(e) {
  if (t(v, l.sidePanel), !b()) throw c;
  o(n(u, "videoEffects.registerEffectParameterChangeHandler"), "video.effectParameterChange", a(e, p), !1), r(n(u, "videoEffects.registerForVideoEffect"), "video.registerForVideoEffect");
}
function V(e) {
  r(n(u, "videoEffects.notifyError"), "video.notifyError", [e]);
}
function b() {
  return t(v) && !!v.supports.video && (!!v.supports.video.mediaStream || !!v.supports.video.sharedFrame);
}
function j() {
  var e;
  return t(v, l.sidePanel) && function () {
    var e, i, r, o;
    return !(!(null === (i = null === (e = d().chrome) || void 0 === e ? void 0 : e.webview) || void 0 === i ? void 0 : i.getTextureStream) || !(null === (o = null === (r = d().chrome) || void 0 === r ? void 0 : r.webview) || void 0 === o ? void 0 : o.registerTextureStream));
  }() && !!(null === (e = v.supports.video) || void 0 === e ? void 0 : e.mediaStream);
}
function S() {
  var e;
  return t(v, l.sidePanel) && !!(null === (e = v.supports.video) || void 0 === e ? void 0 : e.sharedFrame);
}
!function (e) {
  e.NV12 = "NV12";
}(F || (F = {})), function (e) {
  e.EffectChanged = "EffectChanged", e.EffectDisabled = "EffectDisabled";
}(E || (E = {})), function (e) {
  e.InvalidEffectId = "InvalidEffectId", e.InitializationFailure = "InitializationFailure";
}(g || (g = {}));
export { E as EffectChangeType, g as EffectFailureReason, F as VideoFrameFormat, b as isSupported, P as notifySelectedVideoEffectChanged, w as registerForVideoEffect, h as registerForVideoFrame };