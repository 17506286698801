import { __awaiter as t } from "../../../../node_modules/.pnpm/@rollup_plugin-typescript@11.1.6_rollup@4.24.4_tslib@2.6.3_typescript@4.9.5/node_modules/tslib/tslib.es6.js";
import { sendAndHandleSdkError as r } from "../internal/communication.js";
import { GlobalVars as e } from "../internal/globalVars.js";
import { ensureInitialized as o } from "../internal/internalAPIs.js";
import { getApiVersionTag as i } from "../internal/telemetry.js";
import { getBase64StringFromBlob as n, base64ToBlob as s } from "../internal/utils.js";
import { FrameContexts as p, errorNotSupportedOnPlatform as a } from "./constants.js";
import { ClipboardSupportedMimeType as l } from "./interfaces.js";
import { runtime as m } from "./runtime.js";
function c(e) {
  return t(this, void 0, void 0, function* () {
    if (o(m, p.content, p.meetingStage, p.task, p.settings, p.stage, p.sidePanel), !u()) throw a;
    if (!e.type || !Object.values(l).includes(e.type)) throw new Error(`Blob type ${e.type} is not supported. Supported blob types are ${Object.values(l)}`);
    const t = yield n(e),
      s = {
        mimeType: e.type,
        content: t
      };
    return r(i("v2", "clipboard.write"), "clipboard.writeToClipboard", s);
  });
}
function d() {
  return t(this, void 0, void 0, function* () {
    o(m, p.content, p.meetingStage, p.task, p.settings, p.stage, p.sidePanel);
    const t = i("v2", "clipboard.read");
    if (!u()) throw a;
    const e = yield r(t, "clipboard.readFromClipboard");
    if ("string" == typeof e) {
      const t = JSON.parse(e);
      return s(t.mimeType, t.content);
    }
    return e;
  });
}
function u() {
  return e.isFramelessWindow ? !(!o(m) || !m.supports.clipboard) : !!(o(m) && navigator && navigator.clipboard && m.supports.clipboard);
}
export { u as isSupported, d as read, c as write };