import { sendAndHandleSdkError as o } from "../../internal/communication.js";
import { ensureInitialized as t } from "../../internal/internalAPIs.js";
import { getApiVersionTag as n } from "../../internal/telemetry.js";
import { FrameContexts as r, errorNotSupportedOnPlatform as i } from "../constants.js";
import { ErrorCode as e } from "../interfaces.js";
import { runtime as s } from "../runtime.js";
function a() {
  if (t(s, r.content, r.task), !m()) throw i;
  return o(n("v2", "geoLocation.map.chooseLocation"), "location.getLocation", {
    allowChooseLocation: !0,
    showMap: !0
  });
}
function c(a) {
  if (t(s, r.content, r.task), !m()) throw i;
  if (!a) throw {
    errorCode: e.INVALID_ARGUMENTS
  };
  return o(n("v2", "geoLocation.showLocation"), "location.showLocation", a);
}
function m() {
  return !!(t(s) && s.supports.geoLocation && s.supports.geoLocation.map && s.supports.permissions);
}
export { a as chooseLocation, m as isSupported, c as showLocation };