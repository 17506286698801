import { sendAndUnwrap as t } from "../../../internal/communication.js";
import { ensureInitialized as r } from "../../../internal/internalAPIs.js";
import { getApiVersionTag as o } from "../../../internal/telemetry.js";
import { errorNotSupportedOnPlatform as n } from "../../../public/constants.js";
import { runtime as i } from "../../../public/runtime.js";
import * as e from "./joinedTeams.js";
export { e as joinedTeams };
function m(e) {
  return new Promise(m => {
    if (r(i), !s()) throw n;
    m(t(o("v1", "teams.fullTrust.getConfigSetting"), "getConfigSetting", e));
  });
}
function s() {
  return !(!r(i) || !i.supports.teams) && !!i.supports.teams.fullTrust;
}
export { m as getConfigSetting, s as isSupported };