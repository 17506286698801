function e(e) {
  return e ? e.modality && "string" != typeof e.modality ? [!1, "modality must be a string"] : e.targetElementBoundingRect && "object" == typeof e.targetElementBoundingRect ? e.triggerType && "string" == typeof e.triggerType ? function (e) {
    if (!e) return [!1, "persona object must be provided"];
    if (e.displayName && "string" != typeof e.displayName) return [!1, "displayName must be a string"];
    if (!e.identifiers || "object" != typeof e.identifiers) return [!1, "persona identifiers object must be provided"];
    if (!e.identifiers.AadObjectId && !e.identifiers.Smtp && !e.identifiers.Upn) return [!1, "at least one valid identifier must be provided"];
    if (e.identifiers.AadObjectId && "string" != typeof e.identifiers.AadObjectId) return [!1, "AadObjectId identifier must be a string"];
    if (e.identifiers.Smtp && "string" != typeof e.identifiers.Smtp) return [!1, "Smtp identifier must be a string"];
    if (e.identifiers.Upn && "string" != typeof e.identifiers.Upn) return [!1, "Upn identifier must be a string"];
    return [!0, void 0];
  }(e.persona) : [!1, "triggerType must be a valid string"] : [!1, "targetElementBoundingRect must be a DOMRect"] : [!1, "A request object is required"];
}
export { e as validateShowProfileRequest };