import { sendAndHandleSdkError as t } from "../internal/communication.js";
import { ensureInitialized as r } from "../internal/internalAPIs.js";
import { deserializeCart as e, validateUuid as a, validateCartItems as n, serializeCartItems as s, validateCartStatus as o } from "../internal/marketplaceUtils.js";
import { getApiVersionTag as i } from "../internal/telemetry.js";
import { FrameContexts as m, errorNotSupportedOnPlatform as c } from "./constants.js";
import { runtime as d } from "./runtime.js";
const l = "v2",
  u = {
    majorVersion: 1,
    minorVersion: 1
  };
var p, f;
function I() {
  if (r(d, m.content, m.task), !h()) throw c;
  return t(i(l, "marketplace.getCart"), "marketplace.getCart", u).then(e);
}
function v(o) {
  if (r(d, m.content, m.task), !h()) throw c;
  if (!o) throw new Error("addOrUpdateCartItemsParams must be provided");
  return a(null == o ? void 0 : o.cartId), n(null == o ? void 0 : o.cartItems), t(i(l, "marketplace.addOrUpdateCartItems"), "marketplace.addOrUpdateCartItems", Object.assign(Object.assign({}, o), {
    cartItems: s(o.cartItems),
    cartVersion: u
  })).then(e);
}
function C(n) {
  if (r(d, m.content, m.task), !h()) throw c;
  if (!n) throw new Error("removeCartItemsParams must be provided");
  if (a(null == n ? void 0 : n.cartId), !Array.isArray(null == n ? void 0 : n.cartItemIds) || 0 === (null == n ? void 0 : n.cartItemIds.length)) throw new Error("cartItemIds must be a non-empty array");
  return t(i(l, "marketplace.removeCartItems"), "marketplace.removeCartItems", Object.assign(Object.assign({}, n), {
    cartVersion: u
  })).then(e);
}
function k(n) {
  if (r(d, m.content, m.task), !h()) throw c;
  if (!n) throw new Error("updateCartStatusParams must be provided");
  return a(null == n ? void 0 : n.cartId), o(null == n ? void 0 : n.cartStatus), t(i(l, "marketplace.updateCartStatus"), "marketplace.updateCartStatus", Object.assign(Object.assign({}, n), {
    cartVersion: u
  })).then(e);
}
function h() {
  return !(!r(d) || !d.supports.marketplace);
}
!function (t) {
  t.TACAdminUser = "TACAdminUser", t.TeamsAdminUser = "TeamsAdminUser", t.TeamsEndUser = "TeamsEndUser";
}(p || (p = {})), function (t) {
  t.Open = "Open", t.Processing = "Processing", t.Processed = "Processed", t.Closed = "Closed", t.Error = "Error";
}(f || (f = {}));
export { f as CartStatus, p as Intent, v as addOrUpdateCartItems, u as cartVersion, I as getCart, h as isSupported, C as removeCartItems, k as updateCartStatus };