import { __awaiter as t } from "../../../../../node_modules/.pnpm/@rollup_plugin-typescript@11.1.6_rollup@4.24.4_tslib@2.6.3_typescript@4.9.5/node_modules/tslib/tslib.es6.js";
import { callFunctionInHost as o } from "../../internal/communication.js";
import { ensureInitialized as e } from "../../internal/internalAPIs.js";
import { getLogger as r, getApiVersionTag as i } from "../../internal/telemetry.js";
import { getCurrentTimestamp as n } from "../../internal/utils.js";
import { runtime as m } from "../../public/runtime.js";
const s = r("copilot");
function l() {
  var t;
  return e(m) && !!(null === (t = m.supports.copilot) || void 0 === t ? void 0 : t.customTelemetry);
}
function p(r, l) {
  var p;
  return void 0 === l && (l = null !== (p = n()) && void 0 !== p ? p : Date.now()), t(this, void 0, void 0, function* () {
    return e(m), s("Sending custom telemetry data to host for stage: %s to record timestamp: %s", r, l), o("copilot.customTelemetry.sendCustomTelemetryData", [r.toString(), l], i("v2", "copilot.customTelemetry.sendCustomTelemetryData"));
  });
}
export { l as isSupported, p as sendCustomTelemetryData };