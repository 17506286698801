import { sendAndHandleSdkError as t } from "../../internal/communication.js";
import { ensureInitialized as e } from "../../internal/internalAPIs.js";
import { getApiVersionTag as o } from "../../internal/telemetry.js";
import { FrameContexts as r, errorNotSupportedOnPlatform as n } from "../constants.js";
import { runtime as i } from "../runtime.js";
function s() {
  return new Promise(s => {
    if (e(i, r.content), !m()) throw n;
    s(t(o("v2", "stageView.self.close"), "stageView.self.close"));
  });
}
function m() {
  var t;
  return e(i) && void 0 !== (null === (t = i.supports.stageView) || void 0 === t ? void 0 : t.self);
}
export { s as close, m as isSupported };