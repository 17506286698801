import { __awaiter as e } from "../../../../../node_modules/.pnpm/@rollup_plugin-typescript@11.1.6_rollup@4.24.4_tslib@2.6.3_typescript@4.9.5/node_modules/tslib/tslib.es6.js";
import { sendMessageToParent as n, sendAndHandleSdkError as t } from "../../internal/communication.js";
import { registerHandler as i, doesHandlerExist as a, removeHandler as o } from "../../internal/handlers.js";
import { ensureInitialized as r } from "../../internal/internalAPIs.js";
import { getApiVersionTag as l } from "../../internal/telemetry.js";
import { FrameContexts as g } from "../constants.js";
import { ErrorCode as s } from "../interfaces.js";
import { runtime as d } from "../runtime.js";
import * as c from "./appShareButton.js";
export { c as appShareButton };
const u = "v1";
var m, p, h, C, S, f;
function b(e) {
  if (!e) throw new Error("[get incoming client audio state] Callback cannot be null");
  r(d, g.sidePanel, g.meetingStage), n(l(u, "meeting.getIncomingClientAudioState"), "getIncomingClientAudioState", e);
}
function A(e) {
  if (!e) throw new Error("[toggle incoming client audio] Callback cannot be null");
  r(d, g.sidePanel, g.meetingStage), n(l(u, "meeting.toggleIncomingClientAudio"), "toggleIncomingClientAudio", e);
}
function w(e) {
  if (!e) throw new Error("[get meeting details] Callback cannot be null");
  r(d, g.sidePanel, g.meetingStage, g.settings, g.content), n(l(u, "meeting.getMeetingDetails"), "meeting.getMeetingDetails", e);
}
function v() {
  var n, i, a;
  return e(this, void 0, void 0, function* () {
    let e;
    r(d, g.sidePanel, g.meetingStage, g.settings, g.content);
    try {
      const n = !0;
      e = yield t(l("v2", "meeting.getMeetingDetailsVerbose"), "meeting.getMeetingDetails", n);
    } catch (e) {
      throw new Error(null === (n = null == e ? void 0 : e.errorCode) || void 0 === n ? void 0 : n.toString());
    }
    if (((null === (i = e.details) || void 0 === i ? void 0 : i.type) == C.GroupCall || (null === (a = e.details) || void 0 === a ? void 0 : a.type) == C.OneOnOneCall) && !e.details.originalCallerInfo) throw new Error(s.NOT_SUPPORTED_ON_PLATFORM.toString());
    return e;
  });
}
function H(e) {
  if (!e) throw new Error("[get Authentication Token For AnonymousUser] Callback cannot be null");
  r(d, g.sidePanel, g.meetingStage, g.task), n(l(u, "meeting.getAuthenticationTokenForAnonymousUser"), "meeting.getAuthenticationTokenForAnonymousUser", e);
}
function k(e) {
  if (!e) throw new Error("[get live stream state] Callback cannot be null");
  r(d, g.sidePanel), n(l(u, "meeting.getLiveStreamState"), "meeting.getLiveStreamState", e);
}
function _(e, t, i) {
  if (!e) throw new Error("[request start live streaming] Callback cannot be null");
  r(d, g.sidePanel), n(l(u, "meeting.requestStartLiveStreaming"), "meeting.requestStartLiveStreaming", [t, i], e);
}
function M(e) {
  if (!e) throw new Error("[request stop live streaming] Callback cannot be null");
  r(d, g.sidePanel), n(l(u, "meeting.requestStopLiveStreaming"), "meeting.requestStopLiveStreaming", e);
}
function E(e) {
  if (!e) throw new Error("[register live stream changed handler] Handler cannot be null");
  r(d, g.sidePanel), i(l(u, "meeting.registerLiveStreamChangedHandler"), "meeting.liveStreamChanged", e);
}
function P(e, t, i = {
  sharingProtocol: S.Collaborative
}) {
  if (!e) throw new Error("[share app content to stage] Callback cannot be null");
  r(d, g.sidePanel, g.meetingStage), n(l(u, "meeting.shareAppContentToStage"), "meeting.shareAppContentToStage", [t, i], e);
}
function j(e) {
  if (!e) throw new Error("[get app content stage sharing capabilities] Callback cannot be null");
  r(d, g.sidePanel, g.meetingStage), n(l(u, "meeting.getAppContentStageSharingCapabilities"), "meeting.getAppContentStageSharingCapabilities", e);
}
function q(e) {
  if (!e) throw new Error("[stop sharing app content to stage] Callback cannot be null");
  r(d, g.sidePanel, g.meetingStage), n(l(u, "meeting.stopSharingAppContentToStage"), "meeting.stopSharingAppContentToStage", e);
}
function T(e) {
  if (!e) throw new Error("[get app content stage sharing state] Callback cannot be null");
  r(d, g.sidePanel, g.meetingStage), n(l(u, "meeting.getAppContentStageSharingState"), "meeting.getAppContentStageSharingState", e);
}
function D(e) {
  if (!e) throw new Error("[registerSpeakingStateChangeHandler] Handler cannot be null");
  r(d, g.sidePanel, g.meetingStage), i(l(u, "meeting.registerSpeakingStateChangeHandler"), "meeting.speakingStateChanged", e);
}
function y(e) {
  if (!e) throw new Error("[registerRaiseHandStateChangedHandler] Handler cannot be null");
  r(d, g.sidePanel, g.meetingStage), i(l(u, "meeting.registerRaiseHandStateChangedHandler"), "meeting.raiseHandStateChanged", e);
}
function O(e) {
  if (!e) throw new Error("[registerMeetingReactionReceivedHandler] Handler cannot be null");
  r(d, g.sidePanel, g.meetingStage), i(l(u, "meeting.registerMeetingReactionReceivedHandler"), "meeting.meetingReactionReceived", e);
}
function R(e) {
  if (void 0 === (null == e ? void 0 : e.joinWebUrl) || null === (null == e ? void 0 : e.joinWebUrl)) return Promise.reject(new Error("Invalid joinMeetingParams"));
  r(d);
  const n = {
    joinWebUrl: e.joinWebUrl.href,
    source: e.source || f.Other
  };
  return t(l("v2", "meeting.joinMeeting"), "meeting.joinMeeting", n);
}
function I(t, s) {
  if (!s) throw new Error("[requestAppAudioHandling] Callback response cannot be null");
  if (!t.micMuteStateChangedCallback) throw new Error("[requestAppAudioHandling] Callback Mic mute state handler cannot be null");
  r(d, g.sidePanel, g.meetingStage), t.isAppHandlingAudio ? function (t, a) {
    const o = (n, o) => {
      if (n && null != o) throw new Error("[requestAppAudioHandling] Callback response - both parameters cannot be set");
      if (n) throw new Error(`[requestAppAudioHandling] Callback response - SDK error ${n.errorCode} ${n.message}`);
      if ("boolean" != typeof o) throw new Error("[requestAppAudioHandling] Callback response - isHostAudioless must be a boolean");
      const r = n => e(this, void 0, void 0, function* () {
        try {
          const e = yield t.micMuteStateChangedCallback(n);
          F(e, e.isMicMuted === n.isMicMuted ? m.HostInitiated : m.AppDeclinedToChange);
        } catch (e) {
          F(n, m.AppFailedToChange);
        }
      });
      i(l(u, "meeting.registerMicStateChangeHandler"), "meeting.micStateChanged", r);
      const g = e => {
        var n;
        null === (n = t.audioDeviceSelectionChangedCallback) || void 0 === n || n.call(t, e);
      };
      i(l(u, "meeting.registerAudioDeviceSelectionChangedHandler"), "meeting.audioDeviceSelectionChanged", g), a(o);
    };
    n(l(u, "meeting.requestAppAudioHandling"), "meeting.requestAppAudioHandling", [t.isAppHandlingAudio], o);
  }(t, s) : function (e, t) {
    const i = (e, n) => {
      if (e && null != n) throw new Error("[requestAppAudioHandling] Callback response - both parameters cannot be set");
      if (e) throw new Error(`[requestAppAudioHandling] Callback response - SDK error ${e.errorCode} ${e.message}`);
      if ("boolean" != typeof n) throw new Error("[requestAppAudioHandling] Callback response - isHostAudioless must be a boolean");
      a("meeting.micStateChanged") && o("meeting.micStateChanged"), a("meeting.audioDeviceSelectionChanged") && o("meeting.audioDeviceSelectionChanged"), t(n);
    };
    n(l(u, "meeting.requestAppAudioHandling"), "meeting.requestAppAudioHandling", [e.isAppHandlingAudio], i);
  }(t, s);
}
function U(e) {
  F(e, m.AppInitiated);
}
function F(e, t) {
  r(d, g.sidePanel, g.meetingStage), n(l(u, "meeting.setMicStateWithReason"), "meeting.updateMicState", [e, t]);
}
!function (e) {
  e[e.HostInitiated = 0] = "HostInitiated", e[e.AppInitiated = 1] = "AppInitiated", e[e.AppDeclinedToChange = 2] = "AppDeclinedToChange", e[e.AppFailedToChange = 3] = "AppFailedToChange";
}(m || (m = {})), function (e) {
  e.like = "like", e.heart = "heart", e.laugh = "laugh", e.surprised = "surprised", e.applause = "applause";
}(p || (p = {})), function (e) {
  e.Unknown = "Unknown", e.Adhoc = "Adhoc", e.Scheduled = "Scheduled", e.Recurring = "Recurring", e.Broadcast = "Broadcast", e.MeetNow = "MeetNow";
}(h || (h = {})), function (e) {
  e.OneOnOneCall = "oneOnOneCall", e.GroupCall = "groupCall";
}(C || (C = {})), function (e) {
  e.Collaborative = "Collaborative", e.ScreenShare = "ScreenShare";
}(S || (S = {})), function (e) {
  e.M365CalendarGridContextMenu = "m365_calendar_grid_context_menu", e.M365CalendarGridPeek = "m365_calendar_grid_peek", e.M365CalendarGridEventCardJoinButton = "m365_calendar_grid_event_card_join_button", e.M365CalendarFormRibbonJoinButton = "m365_calendar_form_ribbon_join_button", e.M365CalendarFormJoinTeamsMeetingButton = "m365_calendar_form_join_teams_meeting_button", e.Other = "other";
}(f || (f = {}));
export { C as CallType, f as EventActionSource, p as MeetingReactionType, h as MeetingType, S as SharingProtocol, j as getAppContentStageSharingCapabilities, T as getAppContentStageSharingState, H as getAuthenticationTokenForAnonymousUser, b as getIncomingClientAudioState, k as getLiveStreamState, w as getMeetingDetails, v as getMeetingDetailsVerbose, R as joinMeeting, E as registerLiveStreamChangedHandler, O as registerMeetingReactionReceivedHandler, y as registerRaiseHandStateChangedHandler, D as registerSpeakingStateChangeHandler, I as requestAppAudioHandling, _ as requestStartLiveStreaming, M as requestStopLiveStreaming, P as shareAppContentToStage, q as stopSharingAppContentToStage, A as toggleIncomingClientAudio, U as updateMicState };