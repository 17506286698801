import { ensureInitialized as n } from "../internal/internalAPIs.js";
import { returnFocusHelper as t, tabsNavigateToTabHelper as e, navigateCrossDomainHelper as a, backStackNavigateBackHelper as i } from "../internal/pagesHelpers.js";
import { getApiVersionTag as o } from "../internal/telemetry.js";
import { getGenericOnCompleteHandler as s } from "../internal/utils.js";
import { FrameContexts as r } from "./constants.js";
import { runtime as m } from "./runtime.js";
const c = "v1";
function g(n) {
  t(o(c, "navigation.returnFocus"), n);
}
function l(t, a) {
  n(m);
  const i = null != a ? a : s();
  e(o(c, "navigation.navigateToTab"), t).then(() => {
    i(!0);
  }).catch(n => {
    i(!1, n.message);
  });
}
function u(t, e) {
  n(m, r.content, r.sidePanel, r.settings, r.remove, r.task, r.stage, r.meetingStage);
  const i = null != e ? e : s();
  a(o(c, "navigation.navigateCrossDomain"), t).then(() => {
    i(!0);
  }).catch(n => {
    i(!1, n.message);
  });
}
function f(t) {
  n(m);
  const e = null != t ? t : s();
  i(o(c, "navigation.navigateBack")).then(() => {
    e(!0);
  }).catch(n => {
    e(!1, n.message);
  });
}
export { f as navigateBack, u as navigateCrossDomain, l as navigateToTab, g as returnFocus };