import { ensureInitialized as t } from "../internal/internalAPIs.js";
import { configSetValidityStateHelper as e, getConfigHelper as n, configSetConfigHelper as s } from "../internal/pagesHelpers.js";
import { getApiVersionTag as i } from "../internal/telemetry.js";
import { getGenericOnCompleteHandler as r } from "../internal/utils.js";
import { FrameContexts as o } from "./constants.js";
import { runtime as m } from "./runtime.js";
import { registerOnSaveHandlerHelper as a, registerOnRemoveHandlerHelper as g } from "./pages/config.js";
const l = "v1";
function c(t) {
  e(i(l, "settings.setValidityState"), t);
}
function f(e) {
  t(m, o.content, o.settings, o.remove, o.sidePanel), n(i(l, "settings.getSettings")).then(t => {
    e(t);
  });
}
function p(e, n) {
  t(m, o.content, o.settings, o.sidePanel);
  const a = null != n ? n : r();
  s(i(l, "settings.setSettings"), e).then(() => {
    a(!0);
  }).catch(t => {
    a(!1, t.message);
  });
}
function u(t) {
  a(i(l, "settings.registerOnSaveHandler"), t);
}
function j(t) {
  g(i(l, "settings.registerOnRemoveHandler"), t);
}
export { f as getSettings, j as registerOnRemoveHandler, u as registerOnSaveHandler, p as setSettings, c as setValidityState };