import { sendMessageToParent as t } from "../../internal/communication.js";
import { ensureInitialized as r } from "../../internal/internalAPIs.js";
import { initializeBackStackHelper as n, backStackNavigateBackHelper as e, pagesTelemetryVersionNumber as i, setBackButtonPressHandler as o } from "../../internal/pagesHelpers.js";
import { getApiVersionTag as a } from "../../internal/telemetry.js";
import { isNullOrUndefined as s } from "../../internal/typeCheckUtilities.js";
import { errorNotSupportedOnPlatform as p } from "../constants.js";
import { runtime as c } from "../runtime.js";
function m() {
  n();
}
function f() {
  return e(a(i, "pages.backStack.navigateBack"));
}
function u(t) {
  l(a(i, "pages.backStack.registerBackButtonHandler"), t, () => {
    if (!s(t) && !k()) throw p;
  });
}
function l(n, e, i) {
  !s(e) && r(c), i && i(), o(e), !s(e) && t(n, "registerHandler", ["backButton"]);
}
function k() {
  return !(!r(c) || !c.supports.pages) && !!c.supports.pages.backStack;
}
export { m as _initialize, k as isSupported, f as navigateBack, u as registerBackButtonHandler, l as registerBackButtonHandlerHelper };