import { __awaiter as t } from "../../../../node_modules/.pnpm/@rollup_plugin-typescript@11.1.6_rollup@4.24.4_tslib@2.6.3_typescript@4.9.5/node_modules/tslib/tslib.es6.js";
import { sendMessageToParentAsync as e, sendMessageToParent as n, waitForMessageQueue as i, Communication as r } from "../internal/communication.js";
import { ensureInitialized as a, ensureInitializeCalled as o } from "../internal/internalAPIs.js";
import { getApiVersionTag as c } from "../internal/telemetry.js";
import { fullyQualifyUrlString as u, validateUrl as l } from "../internal/utils.js";
import { FrameContexts as s } from "./constants.js";
import { runtime as h } from "./runtime.js";
const f = "v1",
  m = "v2";
let d;
function b(t) {
  d = t;
}
function k(n) {
  const i = void 0 !== n,
    r = i ? n : d;
  if (!r) throw new Error("No parameters are provided for authentication");
  a(h, s.content, s.sidePanel, s.settings, s.remove, s.task, s.stage, s.meetingStage);
  return function (n, i) {
    return t(this, void 0, void 0, function* () {
      const t = u(i.url);
      return l(t), e(n, "authentication.authenticate", [t.href, i.width, i.height, i.isExternal]).then(([t, e]) => {
        if (t) return e;
        throw new Error(e);
      });
    });
  }(r.successCallback || r.failureCallback ? c(f, "authentication.authenticate") : c(m, "authentication.authenticate"), r).then(t => {
    try {
      return r && r.successCallback ? (r.successCallback(t), "") : t;
    } finally {
      i || (d = void 0);
    }
  }).catch(t => {
    try {
      if (r && r.failureCallback) return r.failureCallback(t.message), "";
      throw t;
    } finally {
      i || (d = void 0);
    }
  });
}
function p(t) {
  o();
  return function (t, n) {
    return new Promise(i => {
      i(e(t, "authentication.getAuthToken", [null == n ? void 0 : n.resources, null == n ? void 0 : n.claims, null == n ? void 0 : n.silent, null == n ? void 0 : n.tenantId]));
    }).then(([t, e]) => {
      if (t) return e;
      throw new Error(e);
    });
  }(t && (t.successCallback || t.failureCallback) ? c(f, "authentication.getAuthToken") : c(m, "authentication.getAuthToken"), t).then(e => t && t.successCallback ? (t.successCallback(e), "") : e).catch(e => {
    if (t && t.failureCallback) return t.failureCallback(e.message), "";
    throw e;
  });
}
function C(t) {
  o();
  return function (t) {
    return new Promise(n => {
      n(e(t, "authentication.getUser"));
    }).then(([t, e]) => {
      if (t) return e;
      throw e;
    });
  }(t && (t.successCallback || t.failureCallback) ? c(f, "authentication.getUser") : c(m, "authentication.getUser")).then(e => t && t.successCallback ? (t.successCallback(e), null) : e).catch(e => {
    const n = `Error returned, code = ${e.errorCode}, message = ${e.message}`;
    if (t && t.failureCallback) return t.failureCallback(n), null;
    throw new Error(n);
  });
}
function w(t, e) {
  a(h, s.authentication);
  const o = c(e ? f : m, "authentication.notifySuccess");
  n(o, "authentication.authenticate.success", [t]), i(r.parentWindow, () => setTimeout(() => r.currentWindow.close(), 200));
}
function g(t, e) {
  a(h, s.authentication);
  const o = c(e ? f : m, "authentication.notifyFailure");
  n(o, "authentication.authenticate.failure", [t]), i(r.parentWindow, () => setTimeout(() => r.currentWindow.close(), 200));
}
var v;
!function (t) {
  t.Public = "public", t.EUDB = "eudb", t.Other = "other";
}(v || (v = {}));
export { v as DataResidency, k as authenticate, p as getAuthToken, C as getUser, g as notifyFailure, w as notifySuccess, b as registerAuthenticationHandlers };