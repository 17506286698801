import { callFunctionInHostAndHandleResponse as t } from "../../internal/communication.js";
import { ensureInitialized as r } from "../../internal/internalAPIs.js";
import { SimpleTypeResponseHandler as e, ResponseHandler as n } from "../../internal/responseHandler.js";
import { getApiVersionTag as o } from "../../internal/telemetry.js";
import { runtime as a } from "../../public/runtime.js";
import { isSupported as s } from "./hostEntity.js";
import { ErrorCode as i } from "../../public/interfaces.js";
const l = "v2";
class d extends n {
  validate(t) {
    return "ConfigurableTab" === t.tabType;
  }
  deserialize(t) {
    return t;
  }
}
class c {
  constructor(t) {
    this.configurableTabInstance = t;
  }
  serialize() {
    return this.configurableTabInstance;
  }
}
class u {
  constructor(t) {
    this.hostEntityId = t;
  }
  serialize() {
    return this.hostEntityId;
  }
}
class E extends n {
  validate(t) {
    return "ConfigurableTab" === t.tabType || "StaticTab" === t.tabType;
  }
  deserialize(t) {
    return t;
  }
}
class h {
  constructor(t) {
    this.hostEntityTabInstance = t;
  }
  serialize() {
    return this.hostEntityTabInstance;
  }
}
class p extends n {
  validate(t) {
    return t.allTabs && t.allTabs.forEach(t => {}), !0;
  }
  deserialize(t) {
    return t;
  }
}
function f(e, n) {
  if (r(a), !y()) throw new Error(`Error code: ${i.NOT_SUPPORTED_ON_PLATFORM}, message: Not supported on platform`);
  if (I(e.threadId), n && 0 === n.length) throw new Error(`Error code: ${i.INVALID_ARGUMENTS}, message: App types cannot be an empty array`);
  return t("hostEntity.tab.addAndConfigure", [new u(e), n], new E(), o(l, "hostEntity.tab.addAndConfigure"));
}
function m(e) {
  if (r(a), !y()) throw new Error(`Error code: ${i.NOT_SUPPORTED_ON_PLATFORM}, message: Not supported on platform`);
  return I(e.threadId), t("hostEntity.tab.getAll", [new u(e)], new p(), o(l, "hostEntity.tab.getAll"));
}
function b(e, n) {
  if (r(a), !y()) throw new Error(`Error code: ${i.NOT_SUPPORTED_ON_PLATFORM}, message: Not supported on platform`);
  return g(e), I(n.threadId), t("hostEntity.tab.reconfigure", [new c(e), new u(n)], new d(), o(l, "hostEntity.tab.reconfigure"));
}
function T(e, n) {
  if (r(a), !y()) throw new Error(`Error code: ${i.NOT_SUPPORTED_ON_PLATFORM}, message: Not supported on platform`);
  return g(e), I(n.threadId), t("hostEntity.tab.rename", [new c(e), new u(n)], new d(), o(l, "hostEntity.tab.rename"));
}
function w(n, s) {
  if (r(a), !y()) throw new Error(`Error code: ${i.NOT_SUPPORTED_ON_PLATFORM}, message: Not supported on platform`);
  return I(s.threadId), g(n), t("hostEntity.tab.remove", [new h(n), new u(s)], new e(), o(l, "hostEntity.tab.remove"));
}
function y() {
  var t;
  return !!(r(a) && s() && (null === (t = a.supports.hostEntity) || void 0 === t ? void 0 : t.tab));
}
function I(t) {
  if (!t || 0 == t.length) throw new Error(`Error code: ${i.INVALID_ARGUMENTS}, message: ThreadId cannot be null or empty`);
}
function g(t) {
  if (!(null == t ? void 0 : t.internalTabInstanceId) || 0 === t.internalTabInstanceId.length) throw new Error(`Error code: ${i.INVALID_ARGUMENTS}, message: TabId cannot be null or empty`);
}
export { f as addAndConfigure, m as getAll, y as isSupported, b as reconfigure, w as remove, T as rename };