import { sendMessageToParent as r, sendAndHandleStatusAndReason as e } from "../internal/communication.js";
import { registerHandler as n, removeHandler as t } from "../internal/handlers.js";
import { ensureInitialized as o } from "../internal/internalAPIs.js";
import { getApiVersionTag as s } from "../internal/telemetry.js";
import { FrameContexts as c, errorNotSupportedOnPlatform as a } from "./constants.js";
import { runtime as i } from "./runtime.js";
const h = "v2",
  m = "search.queryChange",
  u = "search.queryClose",
  l = "search.queryExecute";
function f(r, e, t) {
  if (o(i, c.content), !d()) throw a;
  n(s(h, "search.registerOnClosedHandler"), u, r), n(s(h, "search.registerOnExecutedHandler"), l, e), t && n(s(h, "search.registerOnChangeHandler"), m, t);
}
function p() {
  if (o(i, c.content), !d()) throw a;
  r(s(h, "search.unregisterHandlers"), "search.unregister"), t(m), t(u), t(l);
}
function d() {
  return !(!o(i) || !i.supports.search);
}
function g() {
  return new Promise(r => {
    if (o(i, c.content), !d()) throw new Error("Not supported");
    r(e(s(h, "search.closeSearch"), "search.closeSearch"));
  });
}
export { g as closeSearch, d as isSupported, f as registerHandlers, p as unregisterHandlers };