import { __awaiter as t } from "../../../../node_modules/.pnpm/@rollup_plugin-typescript@11.1.6_rollup@4.24.4_tslib@2.6.3_typescript@4.9.5/node_modules/tslib/tslib.es6.js";
import { sendMessageToParentAsync as o, sendAndUnwrap as r } from "../internal/communication.js";
import { ensureInitialized as n } from "../internal/internalAPIs.js";
import { getApiVersionTag as i } from "../internal/telemetry.js";
import { FrameContexts as e, errorNotSupportedOnPlatform as p } from "../public/constants.js";
import { runtime as s } from "../public/runtime.js";
import { validateInput as c } from "./externalAppAuthenticationForCEA.js";
function l(r, l, m) {
  return t(this, void 0, void 0, function* () {
    if (n(s, e.content), !A()) throw p;
    c(r, l);
    const [t, u] = yield o(i("v2", "externalAppCardActionsForCEA.processActionOpenUrl"), "externalAppCardActionsForCEA.processActionOpenUrl", [r.toString(), l, m.href]);
    if (t) throw t;
    return u;
  });
}
function m(o, l, m) {
  return t(this, void 0, void 0, function* () {
    if (n(s, e.content), !A()) throw p;
    c(o, l);
    const t = yield r(i("v2", "externalAppCardActionsForCEA.processActionSubmit"), "externalAppCardActionsForCEA.processActionSubmit", o.toString(), l, m);
    if (t) throw t;
  });
}
function A() {
  return !(!n(s) || !s.supports.externalAppCardActionsForCEA);
}
export { A as isSupported, l as processActionOpenUrl, m as processActionSubmit };