import { __awaiter as t } from "../../../../node_modules/.pnpm/@rollup_plugin-typescript@11.1.6_rollup@4.24.4_tslib@2.6.3_typescript@4.9.5/node_modules/tslib/tslib.es6.js";
import { sendMessageToParentAsync as o } from "../internal/communication.js";
import { ensureInitialized as n } from "../internal/internalAPIs.js";
import { getApiVersionTag as r } from "../internal/telemetry.js";
import { FrameContexts as i, errorNotSupportedOnPlatform as p } from "../public/constants.js";
import { runtime as m } from "../public/runtime.js";
import { AppId as e } from "../public/appId.js";
function s(s, c, u) {
  return t(this, void 0, void 0, function* () {
    if (n(m, i.content), !l()) throw p;
    const t = new e(s),
      [a, d] = yield o(r("v2", "externalAppCommands.processActionCommand"), "externalAppCommands.processActionCommand", [t.toString(), c, u]);
    if (a) throw a;
    return d;
  });
}
function l() {
  return !(!n(m) || !m.supports.externalAppCommands);
}
export { l as isSupported, s as processActionCommand };