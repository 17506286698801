import { sendAndHandleSdkError as t } from "../../internal/communication.js";
import { ensureInitialized as o } from "../../internal/internalAPIs.js";
import { getApiVersionTag as r } from "../../internal/telemetry.js";
import { FrameContexts as e, errorNotSupportedOnPlatform as n } from "../constants.js";
import { runtime as i } from "../runtime.js";
import * as p from "./self.js";
export { p as self };
var a;
function m(p) {
  return new Promise(a => {
    if (o(i, e.content), !s()) throw n;
    if (!p) throw new Error("[stageView.open] Stage view params cannot be null");
    a(t(r("v2", "stageView.open"), "stageView.open", p));
  });
}
function s() {
  return !(!o(i) || !i.supports.stageView);
}
!function (t) {
  t.modal = "modal", t.popout = "popout", t.popoutWithChat = "popoutWithChat";
}(a || (a = {}));
export { a as StageViewOpenMode, s as isSupported, m as open };