import { sendMessageToParent as r } from "../internal/communication.js";
import { registerHandler as t, removeHandler as o } from "../internal/handlers.js";
import { ensureInitialized as e } from "../internal/internalAPIs.js";
import { getApiVersionTag as i } from "../internal/telemetry.js";
import { isNullOrUndefined as n } from "../internal/typeCheckUtilities.js";
import { errorNotSupportedOnPlatform as s } from "../public/constants.js";
import { runtime as m } from "../public/runtime.js";
function l(l) {
  if (!n(l) && e(m), !n(l) && !p()) throw s;
  l ? t(i("v1", "log.request"), "log.request", () => {
    const t = l();
    r(i("v1", "log.receive"), "log.receive", [t]);
  }) : o("log.request");
}
function p() {
  return !(!e(m) || !m.supports.logs);
}
export { p as isSupported, l as registerGetLogHandler };