import { getApiVersionTag as e } from "./telemetry.js";
import { VideoFrameTick as t } from "./videoFrameTick.js";
import { VideoPerformanceStatistics as r } from "./videoPerformanceStatistics.js";
const i = "v2";
class s {
  constructor(t) {
    this.reportPerformanceEvent = t, this.isFirstFrameProcessed = !1, this.frameProcessTimeLimit = 100, this.frameProcessingStartedAt = 0, this.frameProcessingTimeCost = 0, this.processedFrameCount = 0, this.performanceStatistics = new r(s.distributionBinSize, t => this.reportPerformanceEvent(e(i, "videoPerformanceMonitor.performanceDataGenerated"), "video.performance.performanceDataGenerated", [t]));
  }
  startMonitorSlowFrameProcessing() {
    t.setInterval(() => {
      if (0 === this.processedFrameCount) return;
      const t = this.frameProcessingTimeCost / this.processedFrameCount;
      t > this.frameProcessTimeLimit && this.reportPerformanceEvent(e(i, "videoPerformanceMonitor.startMonitorSlowFrameProcessing"), "video.performance.frameProcessingSlow", [t]), this.frameProcessingTimeCost = 0, this.processedFrameCount = 0;
    }, s.calculateFPSInterval);
  }
  setFrameProcessTimeLimit(e) {
    this.frameProcessTimeLimit = e;
  }
  reportApplyingVideoEffect(e, t) {
    var r, i;
    (null === (r = this.applyingEffect) || void 0 === r ? void 0 : r.effectId) === e && (null === (i = this.applyingEffect) || void 0 === i ? void 0 : i.effectParam) === t || (this.applyingEffect = {
      effectId: e,
      effectParam: t
    }, this.appliedEffect = void 0);
  }
  reportVideoEffectChanged(e, t) {
    void 0 === this.applyingEffect || this.applyingEffect.effectId !== e && this.applyingEffect.effectParam !== t || (this.appliedEffect = {
      effectId: e,
      effectParam: t
    }, this.applyingEffect = void 0, this.isFirstFrameProcessed = !1);
  }
  reportStartFrameProcessing(e, r) {
    t.tick(), this.appliedEffect && (this.frameProcessingStartedAt = performance.now(), this.performanceStatistics.processStarts(this.appliedEffect.effectId, e, r, this.appliedEffect.effectParam));
  }
  reportFrameProcessed() {
    var t;
    this.appliedEffect && (this.processedFrameCount++, this.frameProcessingTimeCost += performance.now() - this.frameProcessingStartedAt, this.performanceStatistics.processEnds(), this.isFirstFrameProcessed || (this.isFirstFrameProcessed = !0, this.reportPerformanceEvent(e(i, "videoPerformanceMonitor.reportFrameProcessed"), "video.performance.firstFrameProcessed", [Date.now(), this.appliedEffect.effectId, null === (t = this.appliedEffect) || void 0 === t ? void 0 : t.effectParam])));
  }
  reportGettingTextureStream(e) {
    this.gettingTextureStreamStartedAt = performance.now(), this.currentStreamId = e;
  }
  reportTextureStreamAcquired() {
    if (void 0 !== this.gettingTextureStreamStartedAt) {
      const t = performance.now() - this.gettingTextureStreamStartedAt;
      this.reportPerformanceEvent(e(i, "videoPerformanceMonitor.reportTextureStreamAcquired"), "video.performance.textureStreamAcquired", [this.currentStreamId, t]);
    }
  }
}
s.distributionBinSize = 1e3, s.calculateFPSInterval = 1e3;
export { s as VideoPerformanceMonitor };