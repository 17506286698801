import { sendAndHandleSdkError as t } from "../internal/communication.js";
import { ensureInitialized as e } from "../internal/internalAPIs.js";
import { validateShowProfileRequest as r } from "../internal/profileUtil.js";
import { getApiVersionTag as o } from "../internal/telemetry.js";
import { FrameContexts as n } from "./constants.js";
import { ErrorCode as i } from "./interfaces.js";
import { runtime as m } from "./runtime.js";
function s(s) {
  return e(m, n.content), new Promise(e => {
    const [n, m] = r(s);
    if (!n) throw {
      errorCode: i.INVALID_ARGUMENTS,
      message: m
    };
    const l = {
      modality: s.modality,
      persona: s.persona,
      triggerType: s.triggerType,
      targetRectangle: {
        x: s.targetElementBoundingRect.x,
        y: s.targetElementBoundingRect.y,
        width: s.targetElementBoundingRect.width,
        height: s.targetElementBoundingRect.height
      }
    };
    e(t(o("v2", "profile.showProfile"), "profile.showProfile", l));
  });
}
function l() {
  return !(!e(m) || !m.supports.profile);
}
export { l as isSupported, s as showProfile };