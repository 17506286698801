import { sendAndHandleSdkError as e } from "../internal/communication.js";
import { registerHandler as o } from "../internal/handlers.js";
import { ensureInitialized as n } from "../internal/internalAPIs.js";
import { getApiVersionTag as t } from "../internal/telemetry.js";
import { errorNotSupportedOnPlatform as i } from "../public/constants.js";
import { runtime as r } from "../public/runtime.js";
const m = "v1";
function a() {
  return new Promise(o => {
    if (n(r), !l()) throw i;
    o(e(t(m, "meetingRoom.getPairedMeetingRoomInfo"), "meetingRoom.getPairedMeetingRoomInfo"));
  });
}
function g(o) {
  return new Promise(a => {
    if (!o || 0 == o.length) throw new Error("[meetingRoom.sendCommandToPairedMeetingRoom] Command name cannot be null or empty");
    if (n(r), !l()) throw i;
    a(e(t(m, "meetingRoom.sendCommandToPairedMeetingRoom"), "meetingRoom.sendCommandToPairedMeetingRoom", o));
  });
}
function s(e) {
  if (!e) throw new Error("[meetingRoom.registerMeetingRoomCapabilitiesUpdateHandler] Handler cannot be null");
  if (n(r), !l()) throw i;
  o(t(m, "meetingRoom.registerMeetingRoomCapabilitiesUpdateHandler"), "meetingRoom.meetingRoomCapabilitiesUpdate", o => {
    n(r), e(o);
  });
}
function d(e) {
  if (!e) throw new Error("[meetingRoom.registerMeetingRoomStatesUpdateHandler] Handler cannot be null");
  if (n(r), !l()) throw i;
  o(t(m, "meetingRoom.registerMeetingRoomStatesUpdateHandler"), "meetingRoom.meetingRoomStatesUpdate", o => {
    n(r), e(o);
  });
}
function l() {
  return !(!n(r) || !r.supports.meetingRoom);
}
export { a as getPairedMeetingRoomInfo, l as isSupported, s as registerMeetingRoomCapabilitiesUpdateHandler, d as registerMeetingRoomStatesUpdateHandler, g as sendCommandToPairedMeetingRoom };