import { __awaiter as e } from "../../../../node_modules/.pnpm/@rollup_plugin-typescript@11.1.6_rollup@4.24.4_tslib@2.6.3_typescript@4.9.5/node_modules/tslib/tslib.es6.js";
import { getLogger as n, getApiVersionTag as r } from "./telemetry.js";
import { runtime as l } from "../public/runtime.js";
import { shouldEventBeRelayedToChild as a, sendMessageEventToChild as o } from "./childCommunication.js";
import { sendMessageToParent as t } from "./communication.js";
import { ensureInitialized as d } from "./internalAPIs.js";
import { initializeBackStackHelper as i } from "./pagesHelpers.js";
import { isNullOrUndefined as s } from "./typeCheckUtilities.js";
const u = n("handlers");
class m {
  static initializeHandlers() {
    m.handlers.themeChange = C, m.handlers.load = O, m.handlers.beforeUnload = _, i();
  }
  static uninitializeHandlers() {
    m.handlers = {}, m.themeChangeHandler = null, m.loadHandler = null, m.beforeUnloadHandler = null, m.beforeSuspendOrTerminateHandler = null, m.resumeHandler = null;
  }
}
function f() {
  m.initializeHandlers();
}
function c() {
  m.uninitializeHandlers();
}
m.handlers = {}, m.themeChangeHandler = null, m.loadHandler = null, m.beforeUnloadHandler = null, m.beforeSuspendOrTerminateHandler = null, m.resumeHandler = null, m.hostToAppPerformanceMetricsHandler = null;
const H = u.extend("callHandler");
function h(e, n) {
  const r = m.handlers[e];
  if (r) {
    H("Invoking the registered handler for message %s with arguments %o", e, n);
    return [!0, r.apply(this, n)];
  }
  return a() ? (o(e, n), [!1, void 0]) : (H("Handler for action message %s not found.", e), [!1, void 0]);
}
function p(e, n, r, l = !0, a = []) {
  r ? (m.handlers[n] = r, l && t(e, "registerHandler", [n, ...a])) : delete m.handlers[n];
}
function g(e) {
  delete m.handlers[e];
}
function b(e) {
  return null != m.handlers[e];
}
function U(e, n, r, a, o) {
  r && d(l, ...a), o && o(), p(e, n, r);
}
function T(e, n) {
  m.themeChangeHandler = n, !s(n) && t(e, "registerHandler", ["themeChange"]);
}
function C(e) {
  m.themeChangeHandler && m.themeChangeHandler(e), a() && o("themeChange", [e]);
}
function v(e) {
  m.hostToAppPerformanceMetricsHandler = e;
}
function y(e) {
  m.hostToAppPerformanceMetricsHandler && m.hostToAppPerformanceMetricsHandler(e);
}
function j(e, n) {
  m.loadHandler = n, !s(n) && t(e, "registerHandler", ["load"]);
}
function O(e) {
  const n = {
    entityId: (r = e).entityId,
    contentUrl: new URL(r.contentUrl)
  };
  var r;
  m.resumeHandler ? (m.resumeHandler(n), a() && o("load", [n])) : m.loadHandler && (m.loadHandler(e), a() && o("load", [e]));
}
function S(e, n) {
  m.beforeUnloadHandler = n, !s(n) && t(e, "registerHandler", ["beforeUnload"]);
}
function _() {
  return e(this, void 0, void 0, function* () {
    const e = () => {
      t(r("v2", "handleBeforeUnload"), "readyToUnload", []);
    };
    m.beforeSuspendOrTerminateHandler ? (yield m.beforeSuspendOrTerminateHandler(), a() ? o("beforeUnload") : e()) : m.beforeUnloadHandler && m.beforeUnloadHandler(e) || (a() ? o("beforeUnload") : e());
  });
}
function A(e) {
  m.beforeSuspendOrTerminateHandler = e, !s(e) && t(r("v2", "registerBeforeSuspendOrTerminateHandler"), "registerHandler", ["beforeUnload"]);
}
function P(e) {
  m.resumeHandler = e, !s(e) && t(r("v2", "registerOnResumeHandler"), "registerHandler", ["load"]);
}
export { h as callHandler, b as doesHandlerExist, y as handleHostToAppPerformanceMetrics, C as handleThemeChange, f as initializeHandlers, A as registerBeforeSuspendOrTerminateHandler, S as registerBeforeUnloadHandler, p as registerHandler, U as registerHandlerHelper, v as registerHostToAppPerformanceMetricsHandler, j as registerOnLoadHandler, P as registerOnResumeHandler, T as registerOnThemeChangeHandler, g as removeHandler, c as uninitializeHandlers };