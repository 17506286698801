import { sendAndHandleSdkError as r } from "../internal/communication.js";
import { ensureInitialized as o } from "../internal/internalAPIs.js";
import { getApiVersionTag as t } from "../internal/telemetry.js";
import { isValidHttpsURL as n } from "../internal/utils.js";
import { FrameContexts as e, errorNotSupportedOnPlatform as i } from "./constants.js";
import { ErrorCode as s } from "./interfaces.js";
import { runtime as m } from "./runtime.js";
function a(a) {
  if (o(m, e.content), !l()) throw i;
  if (!a || !n(a)) throw {
    errorCode: s.INVALID_ARGUMENTS,
    message: "Invalid Url: Only https URL is allowed"
  };
  return r(t("v2", "secondaryBrowser.openUrl"), "secondaryBrowser.open", a.toString());
}
function l() {
  return !(!o(m) || !m.supports.secondaryBrowser);
}
export { l as isSupported, a as open };