import { sendAndHandleStatusAndReason as t, sendMessageToParent as n } from "../internal/communication.js";
import { createTeamsDeepLinkForAppInstallDialog as e } from "../internal/deepLinkUtilities.js";
import { ensureInitialized as o } from "../internal/internalAPIs.js";
import { getApiVersionTag as i } from "../internal/telemetry.js";
import { FrameContexts as r } from "./constants.js";
import { runtime as a } from "./runtime.js";
function p(p) {
  return new Promise(l => {
    if (o(a, r.content, r.sidePanel, r.settings, r.task, r.stage, r.meetingStage), !s()) throw new Error("Not supported");
    const m = i("v1", "appInstallDialog.openAppInstallDialog");
    a.isLegacyTeams ? l(t(m, "executeDeepLink", e(p.appId))) : (n(m, "appInstallDialog.openAppInstallDialog", [p]), l());
  });
}
function s() {
  return !(!o(a) || !a.supports.appInstallDialog);
}
export { s as isSupported, p as openAppInstallDialog };