import { sendMessageToParent as t } from "../internal/communication.js";
import { ensureInitialized as i } from "../internal/internalAPIs.js";
import { getApiVersionTag as o } from "../internal/telemetry.js";
import { FrameContexts as n, errorNotSupportedOnPlatform as r } from "../public/constants.js";
import { runtime as s } from "../public/runtime.js";
function m(m) {
  if (i(s, n.content), !c()) throw r;
  t(o("v1", "notifications.showNotification"), "notifications.showNotification", [m]);
}
function c() {
  return !(!i(s) || !s.supports.notifications);
}
export { c as isSupported, m as showNotification };