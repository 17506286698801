import { MediaType as e, FileFormat as n } from "../public/media.js";
import { videoAndImageMediaAPISupportVersion as t, nonFullScreenVideoModeAPISupportVersion as r, imageOutputFormatsAPISupportVersion as l } from "./constants.js";
import { throwExceptionIfMobileApiIsNotSupported as o } from "./internalAPIs.js";
function u(e, n) {
  if (null == e || null == n || e.length <= 0) return null;
  let t = null,
    r = 1;
  return e.sort((e, n) => e.sequence > n.sequence ? 1 : -1), e.forEach(e => {
    e.sequence == r && (t = t ? new Blob([t, e.file], {
      type: n
    }) : new Blob([e.file], {
      type: n
    }), r++);
  }), t;
}
function i(e, n) {
  if (null == e || null == n) return null;
  const t = atob(e.chunk),
    r = new Array(t.length);
  for (let e = 0; e < t.length; e++) r[e] = t.charCodeAt(e);
  const l = new Uint8Array(r),
    o = new Blob([l], {
      type: n
    });
  return {
    sequence: e.chunkSequence,
    file: o
  };
}
function c(e) {
  s(e) ? o(t) : p(e) ? o(r) : d(e) && o(l);
}
function a(n) {
  return !(n.mediaType != e.Video || !n.videoProps || !n.videoProps.videoController);
}
function f(e) {
  return !(null == e || e.maxMediaCount > 10);
}
function d(n) {
  var t;
  return !((null == n ? void 0 : n.mediaType) != e.Image || !(null === (t = null == n ? void 0 : n.imageProps) || void 0 === t ? void 0 : t.imageOutputFormats));
}
function s(n) {
  return !(!n || n.mediaType != e.VideoAndImage && !n.videoAndImageProps);
}
function p(n) {
  return !(!n || n.mediaType != e.Video || !n.videoProps || n.videoProps.isFullScreenMode);
}
function m(e, t, r) {
  return null != e && null != t && t == n.ID && null != r;
}
function I(e) {
  return !(null == e || e.length <= 0 || e.length > 10);
}
function v(e) {
  return !e || !(null === e.timeOutIntervalInSec || null != e.timeOutIntervalInSec && e.timeOutIntervalInSec <= 0 || null != e.timeOutIntervalInSec && e.timeOutIntervalInSec > 60);
}
function g(e) {
  if (e) {
    if (e.title && "string" != typeof e.title) return !1;
    if (e.setSelected && "object" != typeof e.setSelected) return !1;
    if (e.openOrgWideSearchInChatOrChannel && "boolean" != typeof e.openOrgWideSearchInChatOrChannel) return !1;
    if (e.singleSelect && "boolean" != typeof e.singleSelect) return !1;
  }
  return !0;
}
export { u as createFile, i as decodeAttachment, d as isMediaCallForImageOutputFormats, p as isMediaCallForNonFullScreenVideoMode, s as isMediaCallForVideoAndImageInputs, a as isVideoControllerRegistered, c as throwExceptionIfMediaCallIsNotSupportedOnMobile, m as validateGetMediaInputs, g as validatePeoplePickerInput, v as validateScanBarCodeInput, f as validateSelectMediaInputs, I as validateViewImagesInput };