import { __awaiter as i } from "../../../../../node_modules/.pnpm/@rollup_plugin-typescript@11.1.6_rollup@4.24.4_tslib@2.6.3_typescript@4.9.5/node_modules/tslib/tslib.es6.js";
import { sendAndUnwrap as o } from "../../internal/communication.js";
import { ensureInitialized as t } from "../../internal/internalAPIs.js";
import { getLogger as r, getApiVersionTag as e } from "../../internal/telemetry.js";
import { errorNotSupportedOnPlatform as n } from "../../public/constants.js";
import { isSdkError as l } from "../../public/interfaces.js";
import { runtime as s } from "../../public/runtime.js";
const a = r("copilot");
function p() {
  var i, o;
  return t(s) && (!!(null === (i = s.hostVersionsInfo) || void 0 === i ? void 0 : i.appEligibilityInformation) || !!(null === (o = s.supports.copilot) || void 0 === o ? void 0 : o.eligibility));
}
function u() {
  var r, u;
  return i(this, void 0, void 0, function* () {
    if (t(s), !p()) throw new Error(`Error code: ${n.errorCode}, message: Not supported on platform`);
    if (null === (r = s.hostVersionsInfo) || void 0 === r ? void 0 : r.appEligibilityInformation) return a("Eligibility information is already available on runtime."), s.hostVersionsInfo.appEligibilityInformation;
    a("Eligibility information is not available on runtime. Requesting from host.");
    const i = yield o(e("v2", "copilot.eligibility.getEligibilityInfo"), "copilot.eligibility.getEligibilityInfo");
    if (l(i)) throw new Error(`Error code: ${i.errorCode}, message: ${null !== (u = i.message) && void 0 !== u ? u : "Failed to get eligibility information from the host."}`);
    if (!function (i) {
      if (void 0 === i.ageGroup || void 0 === i.cohort || void 0 === i.userClassification || void 0 === i.isCopilotEligible || void 0 === i.isCopilotEnabledRegion || void 0 === i.isOptedOutByAdmin || i.featureSet && (void 0 === i.featureSet.serverFeatures || void 0 === i.featureSet.uxFeatures)) return !1;
      return !0;
    }(i)) throw new Error("Error deserializing eligibility information");
    return i;
  });
}
export { u as getEligibilityInfo, p as isSupported };