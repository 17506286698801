import { AppId as t } from "../public/appId.js";
import { FrameContexts as e, errorNotSupportedOnPlatform as n } from "../public/constants.js";
import { isSupported as i } from "../public/pages/pages.js";
import { runtime as r } from "../public/runtime.js";
import { shouldEventBeRelayedToChild as o, sendMessageEventToChild as s } from "./childCommunication.js";
import { sendAndHandleStatusAndReasonWithDefaultError as a, sendAndUnwrap as c, sendMessageToParent as u, sendAndHandleStatusAndReason as p } from "./communication.js";
import { registerHandler as m } from "./handlers.js";
import { ensureInitialized as f } from "./internalAPIs.js";
import { getApiVersionTag as g } from "./telemetry.js";
import { isSupported as b, navigateBack as l } from "../public/pages/backStack.js";
import { isSupported as d } from "../public/pages/tabs.js";
import { isSupported as w } from "../public/pages/config.js";
const h = "v2";
function P(t, o) {
  return new Promise(s => {
    if (f(r, e.content, e.sidePanel, e.settings, e.remove, e.task, e.stage, e.meetingStage), !i()) throw n;
    s(a(t, "navigateCrossDomain", "Cross-origin navigation is only supported for URLs matching the pattern registered in the manifest.", o));
  });
}
function j(t) {
  return new Promise(e => {
    if (f(r), !b()) throw n;
    e(a(t, "navigateBack", "Back navigation is not supported in the current client or context."));
  });
}
function v(t, e) {
  return new Promise(i => {
    if (f(r), !d()) throw n;
    i(a(t, "navigateToTab", "Invalid internalTabInstanceId and/or channelId were/was provided", e));
  });
}
function I(t, e) {
  if (f(r), !i()) throw n;
  u(t, "returnFocus", [e]);
}
function S(t, e) {
  return new Promise(i => {
    if (f(r), !d()) throw n;
    i(c(t, "getTabInstances", e));
  });
}
function k(t, e) {
  return new Promise(i => {
    if (f(r), !d()) throw n;
    i(c(t, "getMruTabInstances", e));
  });
}
function U(t, o) {
  if (f(r, e.content, e.sidePanel, e.meetingStage), !i()) throw n;
  u(t, "shareDeepLink", [o.subPageId, o.subPageLabel, o.subPageWebUrl]);
}
function B(t, o) {
  if (f(r, e.content), !i()) throw n;
  u(t, "setFrameContext", [o]);
}
function T(t, i) {
  if (f(r, e.settings, e.remove), !w()) throw n;
  u(t, "settings.setValidityState", [i]);
}
function C(t) {
  return new Promise(o => {
    if (f(r, e.content, e.settings, e.remove, e.sidePanel), !i()) throw n;
    o(c(t, "settings.getSettings"));
  });
}
function L(t, i) {
  return new Promise(o => {
    if (f(r, e.content, e.settings, e.sidePanel), !w()) throw n;
    o(p(t, "settings.setSettings", i));
  });
}
function O(e) {
  return e.appId instanceof t;
}
function x(e) {
  return Object.assign(Object.assign({}, e), {
    appId: new t(e.appId),
    webUrl: e.webUrl ? new URL(e.webUrl) : void 0
  });
}
function y(t) {
  return Object.assign(Object.assign({}, t), {
    appId: t.appId.toString(),
    webUrl: t.webUrl ? t.webUrl.toString() : void 0
  });
}
let D;
function F(t) {
  D = t;
}
function R() {
  m(g("v2", "pages.backStack.registerBackButtonPressHandler"), "backButtonPress", A, !1);
}
function A() {
  D && D() || (o() ? s("backButtonPress", []) : l());
}
export { j as backStackNavigateBackHelper, L as configSetConfigHelper, T as configSetValidityStateHelper, y as convertAppNavigationParametersToNavigateToAppParams, x as convertNavigateToAppParamsToAppNavigationParameters, C as getConfigHelper, k as getMruTabInstancesHelper, S as getTabInstancesHelper, R as initializeBackStackHelper, O as isAppNavigationParametersObject, P as navigateCrossDomainHelper, h as pagesTelemetryVersionNumber, I as returnFocusHelper, F as setBackButtonPressHandler, B as setCurrentFrameHelper, U as shareDeepLinkHelper, v as tabsNavigateToTabHelper };