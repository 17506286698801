import { sendMessageToParentAsync as e } from "../internal/communication.js";
import { ensureInitialized as t } from "../internal/internalAPIs.js";
import { ResponseHandler as n } from "../internal/responseHandler.js";
import { getApiVersionTag as i } from "../internal/telemetry.js";
import { isPrimitiveOrPlainObject as r, validateId as o, validateUrl as s } from "../internal/utils.js";
import { FrameContexts as a, errorNotSupportedOnPlatform as u } from "../public/constants.js";
import { runtime as c } from "../public/runtime.js";
import { AppId as h } from "../public/appId.js";
const l = "v2";
class p {
  constructor(e) {
    this.invokeRequest = e;
  }
  serialize() {
    return this.invokeRequest;
  }
}
function f(e) {
  const t = e;
  return t.responseType === R.ActionExecuteInvokeResponse && void 0 !== t.value && void 0 !== t.statusCode && void 0 !== t.type;
}
const m = "Action.Execute";
var d, R, A;
!function (e) {
  e.ActionExecuteInvokeRequest = "ActionExecuteInvokeRequest", e.QueryMessageExtensionRequest = "QueryMessageExtensionRequest";
}(d || (d = {})), function (e) {
  e.ActionExecuteInvokeResponse = "ActionExecuteInvokeResponse", e.QueryMessageExtensionResponse = "QueryMessageExtensionResponse";
}(R || (R = {}));
class x extends n {
  validate(e) {
    return f(e);
  }
  deserialize(e) {
    return e;
  }
}
function w(e) {
  if ("object" != typeof e || null === e) return !1;
  const t = e;
  return Object.values(A).includes(t.errorCode) && (void 0 === t.message || "string" == typeof t.message);
}
function E(e) {
  e.requestType === d.ActionExecuteInvokeRequest ? v(e) : e.requestType === d.QueryMessageExtensionRequest && function (e) {
    if (e.commandId.length > 64) throw new Error("originalRequestInfo.commandId exceeds the maximum size of 64 characters");
    if (e.parameters.length > 5) throw new Error("originalRequestInfo.parameters exceeds the maximum size of 5");
    for (const t of e.parameters) {
      if (t.name.length > 64) throw new Error("originalRequestInfo.parameters.name exceeds the maximum size of 64 characters");
      if (t.value.length > 512) throw new Error("originalRequestInfo.parameters.value exceeds the maximum size of 512 characters");
    }
  }(e);
}
function v(e) {
  if (e.type !== m) {
    throw {
      errorCode: A.INTERNAL_ERROR,
      message: `Invalid action type ${e.type}. Action type must be "${m}"`
    };
  }
  if (!r(e.data)) {
    throw {
      errorCode: A.INTERNAL_ERROR,
      message: `Invalid data type ${typeof e.data}. Data must be a primitive or a plain object.`
    };
  }
}
function g(n, r, o) {
  if (t(c, a.content), !j()) throw u;
  const s = new h(n);
  return E(o), e(i(l, "externalAppAuthentication.authenticateAndResendRequest"), "externalAppAuthentication.authenticateAndResendRequest", [s.toString(), o, r.url.href, r.width, r.height, r.isExternal]).then(([e, t]) => {
    if (e && null != t.responseType) return t;
    throw t;
  });
}
function I(n, r) {
  if (t(c, a.content), !j()) throw u;
  const o = new h(n);
  return e(i(l, "externalAppAuthentication.authenticateWithSSO"), "externalAppAuthentication.authenticateWithSSO", [o.toString(), r.claims, r.silent]).then(([e, t]) => {
    if (!e) throw t;
  });
}
function y(n, r, o) {
  if (t(c, a.content), !j()) throw u;
  const s = new h(n);
  return E(o), e(i(l, "externalAppAuthentication.authenticateWithSSOAndResendRequest"), "externalAppAuthentication.authenticateWithSSOAndResendRequest", [s.toString(), o, r.claims, r.silent]).then(([e, t]) => {
    if (e && null != t.responseType) return t;
    throw t;
  });
}
function q(n, r, s) {
  if (t(c, a.content), !j()) throw u;
  return o(n, new Error("titleId is Invalid.")), o(r, new Error("oauthConfigId is Invalid.")), e(i(l, "externalAppAuthentication.authenticateWithOauth2"), "externalAppAuthentication.authenticateWithOauth2", [n, r, s.width, s.height, s.isExternal]).then(([e, t]) => {
    if (!e) throw t;
  });
}
function S(n, r, h) {
  if (t(c, a.content), !j()) throw u;
  return o(n, new Error("titleId is Invalid.")), r && s(r), e(i(l, "externalAppAuthentication.authenticateWithPowerPlatformConnectorPlugins"), "externalAppAuthentication.authenticateWithPowerPlatformConnectorPlugins", [n, null == r ? void 0 : r.toString(), null == h ? void 0 : h.width, null == h ? void 0 : h.height, null == h ? void 0 : h.isExternal]).then(([e, t]) => {
    if (!e) throw t;
  });
}
function j() {
  return !(!t(c) || !c.supports.externalAppAuthentication);
}
!function (e) {
  e.INTERNAL_ERROR = "INTERNAL_ERROR";
}(A || (A = {}));
export { m as ActionExecuteInvokeRequestType, x as ActionExecuteResponseHandler, A as InvokeErrorCode, R as InvokeResponseType, d as OriginalRequestType, p as SerializableActionExecuteInvokeRequest, g as authenticateAndResendRequest, q as authenticateWithOauth2, S as authenticateWithPowerPlatformConnectorPlugins, I as authenticateWithSSO, y as authenticateWithSSOAndResendRequest, f as isActionExecuteResponse, w as isInvokeError, j as isSupported, v as validateActionExecuteInvokeRequest };