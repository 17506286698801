import { __awaiter as i } from "../../../../../node_modules/.pnpm/@rollup_plugin-typescript@11.1.6_rollup@4.24.4_tslib@2.6.3_typescript@4.9.5/node_modules/tslib/tslib.es6.js";
import { sendAndHandleSdkError as t } from "../../internal/communication.js";
import { ensureInitialized as e } from "../../internal/internalAPIs.js";
import { getApiVersionTag as n } from "../../internal/telemetry.js";
import { maxVisualMediaSelectionLimit as o } from "../../internal/visualMediaHelpers.js";
import { FrameContexts as r, errorNotSupportedOnPlatform as s, errorInvalidCount as a, errorInvalidResponse as u } from "../constants.js";
import { runtime as m } from "../runtime.js";
function l(o) {
  return i(this, void 0, void 0, function* () {
    e(m, r.content, r.task), c(), f(o);
    const i = yield t(n("v2", "visualMedia.image.captureImages"), "visualMedia.image.captureImages", o);
    return v(o.maxVisualMediaCount, i), i;
  });
}
function p(o) {
  return i(this, void 0, void 0, function* () {
    e(m, r.content, r.task), c(), f(o);
    const i = yield t(n("v2", "visualMedia.image.retrieveImages"), "visualMedia.image.retrieveImages", o);
    return v(o.maxVisualMediaCount, i), i;
  });
}
function d() {
  return !!(e(m) && m.supports.visualMedia && m.supports.visualMedia.image && m.supports.permissions);
}
function c() {
  if (!d()) throw s;
}
function f(i) {
  if (!i || i.maxVisualMediaCount > o || i.maxVisualMediaCount < 1) throw a;
}
function v(i, t) {
  if (t.length > i) throw u;
}
export { l as captureImages, d as isSupported, p as retrieveImages };