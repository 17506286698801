import { sendAndHandleSdkError as e } from "../internal/communication.js";
import { ensureInitialized as t } from "../internal/internalAPIs.js";
import { getApiVersionTag as n } from "../internal/telemetry.js";
import { FrameContexts as i } from "./constants.js";
import { runtime as r } from "./runtime.js";
const o = "v2";
var a, s;
function d() {
  return !(!t(r, i.meetingStage, i.sidePanel, i.content) || !r.supports.interactive);
}
!function (e) {
  e.guest = "Guest", e.attendee = "Attendee", e.presenter = "Presenter", e.organizer = "Organizer";
}(a || (a = {})), function (e) {
  e.added = "Added", e.alreadyExists = "AlreadyExists", e.conflict = "Conflict", e.notFound = "NotFound";
}(s || (s = {}));
class l {
  getFluidTenantInfo() {
    return u(), new Promise(t => {
      t(e(n(o, "interactive.getFluidTenantInfo"), "interactive.getFluidTenantInfo"));
    });
  }
  getFluidToken(t) {
    return u(), new Promise(i => {
      i(e(n(o, "interactive.getFluidToken"), "interactive.getFluidToken", t));
    });
  }
  getFluidContainerId() {
    return u(), new Promise(t => {
      t(e(n(o, "interactive.getFluidContainerId"), "interactive.getFluidContainerId"));
    });
  }
  setFluidContainerId(t) {
    return u(), new Promise(i => {
      i(e(n(o, "interactive.setFluidContainerId"), "interactive.setFluidContainerId", t));
    });
  }
  getNtpTime() {
    return u(), new Promise(t => {
      t(e(n(o, "interactive.getNtpTime"), "interactive.getNtpTime"));
    });
  }
  registerClientId(t) {
    return u(), new Promise(i => {
      i(e(n(o, "interactive.registerClientId"), "interactive.registerClientId", t));
    });
  }
  getClientRoles(t) {
    return u(), new Promise(i => {
      i(e(n(o, "interactive.getClientRoles"), "interactive.getClientRoles", t));
    });
  }
  getClientInfo(t) {
    return u(), new Promise(i => {
      i(e(n(o, "interactive.getClientInfo"), "interactive.getClientInfo", t));
    });
  }
  static create() {
    return u(), new l();
  }
}
function u() {
  if (!d()) throw new Error("LiveShareHost Not supported");
}
export { s as ContainerState, l as LiveShareHost, a as UserMeetingRole, d as isSupported };