import { __awaiter as i } from "../../../../node_modules/.pnpm/@rollup_plugin-typescript@11.1.6_rollup@4.24.4_tslib@2.6.3_typescript@4.9.5/node_modules/tslib/tslib.es6.js";
import { sendMessageToParent as e, sendAndHandleStatusAndReason as n, callFunctionInHostAndHandleResponse as t, initializeCommunication as o } from "./communication.js";
import { errorLibraryNotInitialized as r, defaultSDKVersionForCompatCheck as s } from "./constants.js";
import { GlobalVars as l } from "./globalVars.js";
import { registerOnThemeChangeHandler as a, initializeHandlers as p } from "./handlers.js";
import { ensureInitializeCalled as u, ensureInitialized as m, processAdditionalValidOrigins as c } from "./internalAPIs.js";
import { getLogger as f } from "./telemetry.js";
import { isNullOrUndefined as d } from "./typeCheckUtilities.js";
import { inServerSideRenderingEnvironment as g, runWithTimeout as h, compareSDKVersions as S } from "./utils.js";
import { Messages as j } from "../public/app/app.js";
import { FrameContexts as v } from "../public/constants.js";
import { initialize as w } from "../public/dialog/dialog.js";
import { initialize as y } from "../public/menus.js";
import { runtime as z, applyRuntimeConfig as b, generateVersionBasedTeamsRuntimeConfig as P, mapTeamsVersionToSupportedCapabilities as C, versionAndPlatformAgnosticTeamsRuntimeConfig as x } from "../public/runtime.js";
import { version as D } from "../public/version.js";
import { SimpleTypeResponseHandler as E } from "./responseHandler.js";
import { initialize as V } from "../public/pages/config.js";
const K = f("app");
function _(i, e) {
  if (g()) {
    return K.extend("initialize")("window object undefined at initialization"), Promise.resolve();
  }
  return h(() => function (i, e) {
    return new Promise(n => {
      l.initializeCalled || (l.initializeCalled = !0, p(), l.initializePromise = o(e, i).then(({
        context: i,
        clientType: e,
        runtimeConfig: n,
        clientSupportedSDKVersion: t = s
      }) => {
        l.frameContext = i, l.hostClientType = e, l.clientSupportedSDKVersion = t;
        try {
          H("Parsing %s", n);
          const i = JSON.parse(n);
          if (H("Checking if %o is a valid runtime object", null != i ? i : "null"), !i || !i.apiVersion) throw new Error("Received runtime config is invalid");
          n && b(i);
        } catch (i) {
          if (!(i instanceof SyntaxError)) throw i;
          try {
            H("Attempting to parse %s as an SDK version", n), isNaN(S(n, s)) || (l.clientSupportedSDKVersion = n);
            const i = JSON.parse(t);
            if (H("givenRuntimeConfig parsed to %o", null != i ? i : "null"), !i) throw new Error("givenRuntimeConfig string was successfully parsed. However, it parsed to value of null");
            b(i);
          } catch (i) {
            if (!(i instanceof SyntaxError)) throw i;
            b(P(l.clientSupportedSDKVersion, x, C));
          }
        }
        l.initializeCompleted = !0;
      }), y(), V(), w()), Array.isArray(e) && c(e), void 0 !== l.initializePromise ? n(l.initializePromise) : H("GlobalVars.initializePromise is unexpectedly undefined");
    });
  }(i, e), 6e4, new Error("SDK initialization timed out."));
}
function k(i) {
  e(i, j.AppLoaded, [D]);
}
function A(i, n) {
  e(i, j.ExpectedFailure, [n.reason, n.message]);
}
function F(i, n) {
  e(i, j.Failure, [n.reason, n.message]);
}
function N(e) {
  return i(this, void 0, void 0, function* () {
    if (l.initializeCompleted) return R(e);
    if (!l.initializePromise) throw new Error(r);
    return l.initializePromise.then(() => R(e));
  });
}
function R(n) {
  return i(this, void 0, void 0, function* () {
    return m(z) && (null === (i = z.supports.app) || void 0 === i ? void 0 : i.notifySuccessResponse) ? t(j.Success, [D], new E(), n).then(() => ({
      hasFinishedSuccessfully: !0
    })) : (e(n, j.Success, [D]), {
      hasFinishedSuccessfully: "unknown"
    });
    var i;
  });
}
const H = K.extend("initializeHelper");
function J(i, e) {
  !d(e) && u(), a(i, e);
}
function L(i, e) {
  return new Promise(t => {
    m(z, v.content, v.sidePanel, v.settings, v.task, v.stage, v.meetingStage), t(n(i, "executeDeepLink", e));
  });
}
export { _ as appInitializeHelper, R as callNotifySuccessInHost, k as notifyAppLoadedHelper, A as notifyExpectedFailureHelper, F as notifyFailureHelper, N as notifySuccessHelper, L as openLinkHelper, J as registerOnThemeChangeHandlerHelper };