import { callFunctionInHost as o } from "../../internal/communication.js";
import { validateEmailAddress as t } from "../../internal/emailAddressValidation.js";
import { ensureInitialized as n } from "../../internal/internalAPIs.js";
import { getApiVersionTag as r } from "../../internal/telemetry.js";
import { FrameContexts as i } from "../constants.js";
import { runtime as e } from "../runtime.js";
import { ComposeMailType as a } from "./mail.js";
function s(o) {
  o && 0 !== o.length && o.forEach(o => {
    t(o);
  });
}
function m(t) {
  if (n(e, i.content), !f()) throw new Error("Not supported");
  return function (o) {
    if (!o.handoffId || 0 == o.handoffId.trim().length || "" === o.handoffId.trim()) throw new Error("handoffId should not be null or empty string.");
    const t = o.composeMailParams;
    t.type === a.New && (s(t.toRecipients), s(t.ccRecipients), s(t.bccRecipients));
  }(t), o("mail.handoff.composeMail", [new c(t)], r("v2", "mail.handoff.composeMail"));
}
function f() {
  return !!(n(e) && e.supports.mail && e.supports.mail.handoff);
}
class c {
  constructor(o) {
    this.composeMailParamsWithHandoff = o;
  }
  serialize() {
    return this.composeMailParamsWithHandoff;
  }
}
export { m as composeMailWithHandoff, f as isSupported };