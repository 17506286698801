import { __awaiter as e } from "../../../../node_modules/.pnpm/@rollup_plugin-typescript@11.1.6_rollup@4.24.4_tslib@2.6.3_typescript@4.9.5/node_modules/tslib/tslib.es6.js";
import { errorNotSupportedOnPlatform as t } from "../public/constants.js";
import { EffectFailureReason as r } from "../public/videoEffects.js";
import { sendMessageToParent as i } from "./communication.js";
import { registerHandler as o } from "./handlers.js";
import { getApiVersionTag as a } from "./telemetry.js";
import { inServerSideRenderingEnvironment as n, ssrSafeWindow as s } from "./utils.js";
const d = "v2";
function h(t, r, i, o) {
  var a, s;
  return e(this, void 0, void 0, function* () {
    const e = f();
    !n() && (null === (s = null === (a = window.chrome) || void 0 === a ? void 0 : a.webview) || void 0 === s || s.registerTextureStream(t, e)), l(yield u(t, i, o), new m(i, r), e.writable);
  });
}
function c(t, r, i, o) {
  var a, s;
  return e(this, void 0, void 0, function* () {
    const e = f();
    !n() && (null === (s = null === (a = window.chrome) || void 0 === a ? void 0 : a.webview) || void 0 === s || s.registerTextureStream(t, e)), l(yield u(t, i, o), new v(i, r), e.writable);
  });
}
function u(r, i, o) {
  return e(this, void 0, void 0, function* () {
    if (n()) throw t;
    const e = s().chrome;
    try {
      null == o || o.reportGettingTextureStream(r);
      const t = (yield e.webview.getTextureStream(r)).getVideoTracks();
      if (0 === t.length) throw new Error(`No video track in stream ${r}`);
      return null == o || o.reportTextureStreamAcquired(), t[0];
    } catch (e) {
      throw i(`Failed to get video track from stream ${r}, error: ${e}`), new Error(`Internal error: can't get video track from stream ${r}`);
    }
  });
}
function f() {
  if (n()) throw t;
  const e = window.MediaStreamTrackGenerator;
  if (!e) throw t;
  return new e({
    kind: "video"
  });
}
function l(e, t, r) {
  new (0, s().MediaStreamTrackProcessor)({
    track: e
  }).readable.pipeThrough(new TransformStream(t)).pipeTo(r);
}
class m {
  constructor(t, r) {
    this.notifyError = t, this.videoFrameHandler = r, this.transform = (t, r) => e(this, void 0, void 0, function* () {
      const e = t.timestamp;
      if (null !== e) try {
        const i = yield this.videoFrameHandler({
            videoFrame: t
          }),
          o = new VideoFrame(i, {
            timestamp: e
          });
        r.enqueue(o), t.close(), i.close();
      } catch (e) {
        t.close(), this.notifyError(e);
      } else this.notifyError("timestamp of the original video frame is null");
    });
  }
}
class w {
  constructor(e, t) {
    if (this.headerBuffer = e, this.notifyError = t, this.ONE_TEXTURE_INPUT_ID = 1869900081, this.INVALID_HEADER_ERROR = "Invalid video frame header", this.UNSUPPORTED_LAYOUT_ERROR = "Unsupported texture layout", this.headerDataView = new Uint32Array(e), this.headerDataView.length < 8) throw this.notifyError(this.INVALID_HEADER_ERROR), new Error(this.INVALID_HEADER_ERROR);
    if (this.headerDataView[0] !== this.ONE_TEXTURE_INPUT_ID) throw this.notifyError(this.UNSUPPORTED_LAYOUT_ERROR), new Error(this.UNSUPPORTED_LAYOUT_ERROR);
  }
  get oneTextureLayoutId() {
    return this.headerDataView[0];
  }
  get version() {
    return this.headerDataView[1];
  }
  get frameRowOffset() {
    return this.headerDataView[2];
  }
  get frameFormat() {
    return this.headerDataView[3];
  }
  get frameWidth() {
    return this.headerDataView[4];
  }
  get frameHeight() {
    return this.headerDataView[5];
  }
  get multiStreamHeaderRowOffset() {
    return this.headerDataView[6];
  }
  get multiStreamCount() {
    return this.headerDataView[7];
  }
}
class E {
  constructor(e, t) {
    this.metadataMap = new Map(), this.AUDIO_INFERENCE_RESULT_STREAM_ID = 828664161, this.ATTRIBUTE_ID_MAP_STREAM_ID = 1296320833;
    const r = new Uint32Array(e);
    for (let i = 0, o = 0; i < t; i++) {
      const t = r[o++],
        i = r[o++],
        a = r[o++],
        n = new Uint8Array(e, i, a);
      this.metadataMap.set(t, n);
    }
  }
  get audioInferenceResult() {
    return this.metadataMap.get(this.AUDIO_INFERENCE_RESULT_STREAM_ID);
  }
  get attributes() {
    const e = this.metadataMap.get(this.ATTRIBUTE_ID_MAP_STREAM_ID);
    if (void 0 === e) return;
    const t = new Map(),
      r = new TextDecoder("utf-8");
    let i = 0;
    const o = e[i] + (e[++i] << 8) + (e[++i] << 16) + (e[++i] << 24);
    for (let a = 0; a < o && i < e.length - 1; a++) {
      const o = e[++i] + (e[++i] << 8) + (e[++i] << 16) + (e[++i] << 24),
        a = e.findIndex((e, t, r) => 0 == e && t > i),
        n = r.decode(e.slice(++i, a)),
        s = this.metadataMap.get(o);
      void 0 !== s && t.set(n, s);
      i = a + (4 - (a - i) % 4 - 1);
    }
    return t;
  }
}
class v {
  constructor(r, i) {
    this.notifyError = r, this.videoFrameHandler = i, this.shouldDiscardAudioInferenceResult = !1, this.transform = (t, r) => e(this, void 0, void 0, function* () {
      const e = t.timestamp;
      if (null !== e) try {
        const {
            videoFrame: i,
            metadata: {
              audioInferenceResult: o,
              attributes: a
            } = {}
          } = yield this.extractVideoFrameAndMetadata(t),
          n = yield this.videoFrameHandler({
            videoFrame: i,
            audioInferenceResult: o,
            attributes: a
          }),
          s = new VideoFrame(n, {
            timestamp: e
          });
        r.enqueue(s), i.close(), t.close(), n.close();
      } catch (e) {
        t.close(), this.notifyError(e);
      } else this.notifyError("timestamp of the original video frame is null");
    }), this.extractVideoFrameAndMetadata = r => e(this, void 0, void 0, function* () {
      if (n()) throw t;
      if ("NV12" !== r.format) throw this.notifyError("Unsupported video frame pixel format"), new Error("Unsupported video frame pixel format");
      const e = {
          x: 0,
          y: 0,
          width: r.codedWidth,
          height: 2
        },
        i = new ArrayBuffer(e.width * e.height * 3 / 2);
      yield r.copyTo(i, {
        rect: e
      });
      const o = new w(i, this.notifyError),
        a = {
          x: 0,
          y: o.multiStreamHeaderRowOffset,
          width: r.codedWidth,
          height: r.codedHeight - o.multiStreamHeaderRowOffset
        },
        s = new ArrayBuffer(a.width * a.height * 3 / 2);
      yield r.copyTo(s, {
        rect: a
      });
      const d = new E(s, o.multiStreamCount);
      return {
        videoFrame: new VideoFrame(r, {
          timestamp: r.timestamp,
          visibleRect: {
            x: 0,
            y: o.frameRowOffset,
            width: o.frameWidth,
            height: o.frameHeight
          }
        }),
        metadata: {
          audioInferenceResult: this.shouldDiscardAudioInferenceResult ? void 0 : d.audioInferenceResult,
          attributes: d.attributes
        }
      };
    }), o(a(d, "videoEffectsUtils.transformerWithMetadata.constructor"), "video.mediaStream.audioInferenceDiscardStatusChange", ({
      discardAudioInferenceResult: e
    }) => {
      this.shouldDiscardAudioInferenceResult = e;
    });
  }
}
function p(e, t) {
  return (o, n) => {
    null == t || t.reportApplyingVideoEffect(o || "", n), e(o, n).then(() => {
      null == t || t.reportVideoEffectChanged(o || "", n), i(a(d, "videoEffectsUtils.reportVideoEffectChanged"), "video.videoEffectReadiness", [!0, o, void 0, n]);
    }).catch(e => {
      const t = e in r ? e : r.InitializationFailure;
      i(a(d, "videoEffectsUtils.effectFailure"), "video.videoEffectReadiness", [!1, o, t, n]);
    });
  };
}
export { p as createEffectParameterChangeCallback, h as processMediaStream, c as processMediaStreamWithMetadata };