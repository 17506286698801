import { sendMessageToParent as r } from "../internal/communication.js";
import { registerHandler as e } from "../internal/handlers.js";
import { ensureInitialized as o } from "../internal/internalAPIs.js";
import { getApiVersionTag as n } from "../internal/telemetry.js";
import { FrameContexts as t, errorNotSupportedOnPlatform as a } from "../public/constants.js";
import { runtime as m } from "../public/runtime.js";
const l = "v1";
var i, C, s;
function d(e) {
  if (!e) throw new Error("[remoteCamera.getCapableParticipants] Callback cannot be null");
  if (o(m, t.sidePanel), !b()) throw a;
  r(n(l, "remoteCamera.getCapableParticipants"), "remoteCamera.getCapableParticipants", e);
}
function c(e, i) {
  if (!e) throw new Error("[remoteCamera.requestControl] Participant cannot be null");
  if (!i) throw new Error("[remoteCamera.requestControl] Callback cannot be null");
  if (o(m, t.sidePanel), !b()) throw a;
  r(n(l, "remoteCamera.requestControl"), "remoteCamera.requestControl", [e], i);
}
function u(e, i) {
  if (!e) throw new Error("[remoteCamera.sendControlCommand] ControlCommand cannot be null");
  if (!i) throw new Error("[remoteCamera.sendControlCommand] Callback cannot be null");
  if (o(m, t.sidePanel), !b()) throw a;
  r(n(l, "remoteCamera.sendControlCommand"), "remoteCamera.sendControlCommand", [e], i);
}
function f(e) {
  if (!e) throw new Error("[remoteCamera.terminateSession] Callback cannot be null");
  if (o(m, t.sidePanel), !b()) throw a;
  r(n(l, "remoteCamera.terminateSession"), "remoteCamera.terminateSession", e);
}
function h(r) {
  if (!r) throw new Error("[remoteCamera.registerOnCapableParticipantsChangeHandler] Handler cannot be null");
  if (o(m, t.sidePanel), !b()) throw a;
  e(n(l, "remoteCamera.registerOnCapableParticipantsChangeHandler"), "remoteCamera.capableParticipantsChange", r);
}
function w(r) {
  if (!r) throw new Error("[remoteCamera.registerOnErrorHandler] Handler cannot be null");
  if (o(m, t.sidePanel), !b()) throw a;
  e(n(l, "remoteCamera.registerOnErrorHandler"), "remoteCamera.handlerError", r);
}
function E(r) {
  if (!r) throw new Error("[remoteCamera.registerOnDeviceStateChangeHandler] Handler cannot be null");
  if (o(m, t.sidePanel), !b()) throw a;
  e(n(l, "remoteCamera.registerOnDeviceStateChangeHandler"), "remoteCamera.deviceStateChange", r);
}
function p(r) {
  if (!r) throw new Error("[remoteCamera.registerOnSessionStatusChangeHandler] Handler cannot be null");
  if (o(m, t.sidePanel), !b()) throw a;
  e(n(l, "remoteCamera.registerOnSessionStatusChangeHandler"), "remoteCamera.sessionStatusChange", r);
}
function b() {
  return !(!o(m) || !m.supports.remoteCamera);
}
!function (r) {
  r.Reset = "Reset", r.ZoomIn = "ZoomIn", r.ZoomOut = "ZoomOut", r.PanLeft = "PanLeft", r.PanRight = "PanRight", r.TiltUp = "TiltUp", r.TiltDown = "TiltDown";
}(i || (i = {})), function (r) {
  r[r.CommandResetError = 0] = "CommandResetError", r[r.CommandZoomInError = 1] = "CommandZoomInError", r[r.CommandZoomOutError = 2] = "CommandZoomOutError", r[r.CommandPanLeftError = 3] = "CommandPanLeftError", r[r.CommandPanRightError = 4] = "CommandPanRightError", r[r.CommandTiltUpError = 5] = "CommandTiltUpError", r[r.CommandTiltDownError = 6] = "CommandTiltDownError", r[r.SendDataError = 7] = "SendDataError";
}(C || (C = {})), function (r) {
  r[r.None = 0] = "None", r[r.ControlDenied = 1] = "ControlDenied", r[r.ControlNoResponse = 2] = "ControlNoResponse", r[r.ControlBusy = 3] = "ControlBusy", r[r.AckTimeout = 4] = "AckTimeout", r[r.ControlTerminated = 5] = "ControlTerminated", r[r.ControllerTerminated = 6] = "ControllerTerminated", r[r.DataChannelError = 7] = "DataChannelError", r[r.ControllerCancelled = 8] = "ControllerCancelled", r[r.ControlDisabled = 9] = "ControlDisabled", r[r.ControlTerminatedToAllowOtherController = 10] = "ControlTerminatedToAllowOtherController";
}(s || (s = {}));
export { i as ControlCommand, C as ErrorReason, s as SessionTerminatedReason, d as getCapableParticipants, b as isSupported, h as registerOnCapableParticipantsChangeHandler, E as registerOnDeviceStateChangeHandler, w as registerOnErrorHandler, p as registerOnSessionStatusChangeHandler, c as requestControl, u as sendControlCommand, f as terminateSession };