import { sendAndHandleSdkError as r } from "../../internal/communication.js";
import { ensureInitialized as t } from "../../internal/internalAPIs.js";
import { getApiVersionTag as n } from "../../internal/telemetry.js";
import { FrameContexts as o, errorNotSupportedOnPlatform as e } from "../constants.js";
import { DevicePermission as i } from "../interfaces.js";
import { runtime as s } from "../runtime.js";
import { isSupported as a } from "./image.js";
import * as m from "./image.js";
export { m as image };
var f, l;
function c() {
  if (t(s, o.content, o.task), !a()) throw e;
  const m = i.Media;
  return r(n("v2", "visualMedia.hasPermission"), "permissions.has", m);
}
function p() {
  if (t(s, o.content, o.task), !a()) throw e;
  const m = i.Media;
  return r(n("v2", "visualMedia.requestPermission"), "permissions.request", m);
}
!function (r) {
  r[r.FrontOrRear = 1] = "FrontOrRear", r[r.FrontOnly = 2] = "FrontOnly", r[r.RearOnly = 3] = "RearOnly";
}(f || (f = {})), function (r) {
  r[r.Camera = 1] = "Camera", r[r.Gallery = 2] = "Gallery";
}(l || (l = {}));
export { f as CameraRestriction, l as Source, c as hasPermission, p as requestPermission };