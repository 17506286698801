import { __awaiter as t } from "../../../../node_modules/.pnpm/@rollup_plugin-typescript@11.1.6_rollup@4.24.4_tslib@2.6.3_typescript@4.9.5/node_modules/tslib/tslib.es6.js";
import { validateAppIdInstance as n } from "../internal/appIdValidation.js";
import { callFunctionInHost as e, callFunctionInHostAndHandleResponse as i } from "../internal/communication.js";
import { ensureInitialized as o } from "../internal/internalAPIs.js";
import { getApiVersionTag as r } from "../internal/telemetry.js";
import { validateId as a } from "../internal/utils.js";
import { FrameContexts as u, errorNotSupportedOnPlatform as c } from "../public/constants.js";
import { runtime as p } from "../public/runtime.js";
import { validateActionExecuteInvokeRequest as h, ActionExecuteResponseHandler as s, SerializableActionExecuteInvokeRequest as l, isInvokeError as A } from "./externalAppAuthentication.js";
const m = "v2";
function d(n, i, a) {
  return t(this, void 0, void 0, function* () {
    if (o(p, u.content), !v()) throw c;
    return w(n, i), e("externalAppAuthenticationForCEA.authenticateWithSSO", [n, i, a.authId, a.connectionName, a.claims, a.silent], r(m, "externalAppAuthenticationForCEA.authenticateWithSSO"), A);
  });
}
function f(n, i, a) {
  return t(this, void 0, void 0, function* () {
    if (o(p, u.content), !v()) throw c;
    return w(n, i), e("externalAppAuthenticationForCEA.authenticateWithOauth", [n, i, a.url.href, a.width, a.height, a.isExternal], r(m, "externalAppAuthenticationForCEA.authenticateWithOauth"), A);
  });
}
function x(n, e, a, d) {
  return t(this, void 0, void 0, function* () {
    if (o(p, u.content), !v()) throw c;
    return w(n, e), h(d), i("externalAppAuthenticationForCEA.authenticateAndResendRequest", [n, e, new l(d), a.url.href, a.width, a.height, a.isExternal], new s(), r(m, "externalAppAuthenticationForCEA.authenticateAndResendRequest"), A);
  });
}
function E(n, e, a, d) {
  return t(this, void 0, void 0, function* () {
    if (o(p, u.content), !v()) throw c;
    return w(n, e), h(d), i("externalAppAuthenticationForCEA.authenticateWithSSOAndResendRequest", [n, e, new l(d), a.authId, a.connectionName, a.claims, a.silent], new s(), r(m, "externalAppAuthenticationForCEA.authenticateWithSSOAndResendRequest"), A);
  });
}
function v() {
  return !(!o(p) || !p.supports.externalAppAuthenticationForCEA);
}
function w(t, e) {
  a(e, new Error("conversation id is not valid.")), n(t);
}
export { x as authenticateAndResendRequest, f as authenticateWithOauth, d as authenticateWithSSO, E as authenticateWithSSOAndResendRequest, v as isSupported, w as validateInput };