import { __awaiter as t } from "../../../../node_modules/.pnpm/@rollup_plugin-typescript@11.1.6_rollup@4.24.4_tslib@2.6.3_typescript@4.9.5/node_modules/tslib/tslib.es6.js";
import { sendAndUnwrap as e } from "../internal/communication.js";
import { GlobalVars as o } from "../internal/globalVars.js";
import { ensureInitialized as r } from "../internal/internalAPIs.js";
import { getApiVersionTag as i } from "../internal/telemetry.js";
import { getCachedHostName as n } from "../internal/webStorageHelpers.js";
import { errorNotSupportedOnPlatform as s, HostClientType as l, HostName as p } from "./constants.js";
import { runtime as m } from "./runtime.js";
function a() {
  return t(this, void 0, void 0, function* () {
    if (r(m), !u()) throw s;
    return !(!m.isLegacyTeams || o.hostClientType !== l.android && o.hostClientType !== l.ios && o.hostClientType !== l.ipados && o.hostClientType !== l.visionOS || (yield function () {
      return t(this, void 0, void 0, function* () {
        return n();
      });
    }()) !== p.teams) || (yield e(i("v2", "webStorage.isWebStorageClearedOnUserLogOut"), "webStorage.isWebStorageClearedOnUserLogOut"));
  });
}
function u() {
  return r(m) && void 0 !== m.supports.webStorage;
}
export { u as isSupported, a as isWebStorageClearedOnUserLogOut };