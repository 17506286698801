import { sendAndHandleSdkError as o } from "../../internal/communication.js";
import { ensureInitialized as t } from "../../internal/internalAPIs.js";
import { getApiVersionTag as n } from "../../internal/telemetry.js";
import { FrameContexts as r, errorNotSupportedOnPlatform as i } from "../constants.js";
import { DevicePermission as s } from "../interfaces.js";
import { runtime as e } from "../runtime.js";
import * as a from "./map.js";
export { a as map };
const m = "v2";
function c() {
  if (t(e, r.content, r.task), !u()) throw i;
  return o(n(m, "geoLocation.getCurrentLocation"), "location.getLocation", {
    allowChooseLocation: !1,
    showMap: !1
  });
}
function p() {
  if (t(e, r.content, r.task), !u()) throw i;
  const a = s.GeoLocation;
  return new Promise(t => {
    t(o(n(m, "geoLocation.hasPermission"), "permissions.has", a));
  });
}
function f() {
  if (t(e, r.content, r.task), !u()) throw i;
  const a = s.GeoLocation;
  return new Promise(t => {
    t(o(n(m, "geoLocation.requestPermission"), "permissions.request", a));
  });
}
function u() {
  return !!(t(e) && e.supports.geoLocation && e.supports.permissions);
}
export { c as getCurrentLocation, p as hasPermission, u as isSupported, f as requestPermission };