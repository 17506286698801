import { sendMessageToParent as e, sendAndHandleSdkError as t } from "../internal/communication.js";
import { captureImageMobileSupportVersion as i, mediaAPISupportVersion as n, getMediaCallbackSupportVersion as o, nonFullScreenVideoModeAPISupportVersion as r, scanBarCodeAPIMobileSupportVersion as a } from "../internal/constants.js";
import { GlobalVars as s } from "../internal/globalVars.js";
import { registerHandler as l, removeHandler as c } from "../internal/handlers.js";
import { ensureInitialized as d, isCurrentSDKVersionAtLeast as m, throwExceptionIfMobileApiIsNotSupported as u } from "../internal/internalAPIs.js";
import { validateGetMediaInputs as h, throwExceptionIfMediaCallIsNotSupportedOnMobile as f, validateSelectMediaInputs as p, isVideoControllerRegistered as C, validateViewImagesInput as T, validateScanBarCodeInput as g, createFile as M, decodeAttachment as b } from "../internal/mediaUtil.js";
import { getLogger as R, getApiVersionTag as k } from "../internal/telemetry.js";
import { isNullOrUndefined as w } from "../internal/typeCheckUtilities.js";
import { generateGUID as A } from "../internal/utils.js";
import { FrameContexts as y, errorNotSupportedOnPlatform as v, HostClientType as I } from "./constants.js";
import { ErrorCode as E, DevicePermission as P } from "./interfaces.js";
import { runtime as O } from "./runtime.js";
const L = "v1",
  D = R("media");
var S, N, _, B, V, j, F;
!function (e) {
  e.Base64 = "base64", e.ID = "id";
}(S || (S = {}));
class U {}
function G(t) {
  if (!t) throw new Error("[captureImage] Callback cannot be null");
  if (d(O, y.content, y.task), s.isFramelessWindow) {
    if (m(i)) e(k(L, "media.captureImage"), "captureImage", t);else {
      t({
        errorCode: E.OLD_PLATFORM
      }, []);
    }
  } else {
    t({
      errorCode: E.NOT_SUPPORTED_ON_PLATFORM
    }, []);
  }
}
function q() {
  if (d(O, y.content, y.task), !W()) throw v;
  const e = P.Media;
  return new Promise(i => {
    i(t(k(L, "media.hasPermission"), "permissions.has", e));
  });
}
function H() {
  if (d(O, y.content, y.task), !W()) throw v;
  const e = P.Media;
  return new Promise(i => {
    i(t(k(L, "media.requestPermission"), "permissions.request", e));
  });
}
function W() {
  return !(!d(O) || !O.supports.permissions);
}
class x extends U {
  constructor(e) {
    super(), e && (this.content = e.content, this.format = e.format, this.mimeType = e.mimeType, this.name = e.name, this.preview = e.preview, this.size = e.size);
  }
  getMedia(e) {
    if (!e) throw new Error("[get Media] Callback cannot be null");
    if (d(O, y.content, y.task), m(n)) {
      if (h(this.mimeType, this.format, this.content)) m(o) ? this.getMediaViaCallback(e) : this.getMediaViaHandler(e);else {
        e({
          errorCode: E.INVALID_ARGUMENTS
        }, new Blob());
      }
    } else {
      e({
        errorCode: E.OLD_PLATFORM
      }, new Blob());
    }
  }
  getMediaViaCallback(t) {
    const i = {
        mediaMimeType: this.mimeType,
        assembleAttachment: []
      },
      n = [this.content];
    e(k(L, "media.getMedia"), "getMedia", n, function (e) {
      if (t) if (e && e.error) t(e.error, new Blob());else if (e && e.mediaChunk) {
        if (e.mediaChunk.chunkSequence <= 0) {
          const n = M(i.assembleAttachment, i.mediaMimeType);
          t(e.error, null != n ? n : new Blob());
        } else {
          const t = b(e.mediaChunk, i.mediaMimeType);
          t ? i.assembleAttachment.push(t) : D(`Received a null assemble attachment for when decoding chunk sequence ${e.mediaChunk.chunkSequence}; not including the chunk in the assembled file.`);
        }
      } else t({
        errorCode: E.INTERNAL_ERROR,
        message: "data received is null"
      }, new Blob());
    });
  }
  getMediaViaHandler(t) {
    const i = A(),
      n = {
        mediaMimeType: this.mimeType,
        assembleAttachment: []
      },
      o = [i, this.content];
    this.content && !w(t) && e(k(L, "media.getMedia"), "getMedia", o), l(k(L, "media.registerGetMediaRequestHandler"), "getMedia" + i, function (e) {
      if (t) {
        const o = JSON.parse(e);
        if (o.error) t(o.error, new Blob()), c("getMedia" + i);else if (o.mediaChunk) {
          if (o.mediaChunk.chunkSequence <= 0) {
            const e = M(n.assembleAttachment, n.mediaMimeType);
            t(o.error, null != e ? e : new Blob()), c("getMedia" + i);
          } else {
            const e = b(o.mediaChunk, n.mediaMimeType);
            e && n.assembleAttachment.push(e);
          }
        } else t({
          errorCode: E.INTERNAL_ERROR,
          message: "data received is null"
        }, new Blob()), c("getMedia" + i);
      }
    });
  }
}
class z {
  constructor(e) {
    this.controllerCallback = e;
  }
  notifyEventToHost(t, i) {
    d(O, y.content, y.task);
    try {
      u(r);
    } catch (e) {
      return void (i && i(e));
    }
    const n = {
      mediaType: this.getMediaType(),
      mediaControllerEvent: t
    };
    e(k(L, "media.controller"), "media.controller", [n], e => {
      i && i(e);
    });
  }
  stop(e) {
    this.notifyEventToHost(N.StopRecording, e);
  }
}
class J extends z {
  getMediaType() {
    return V.Video;
  }
  notifyEventToApp(e) {
    if (this.controllerCallback) switch (e) {
      case N.StartRecording:
        if (this.controllerCallback.onRecordingStarted) {
          this.controllerCallback.onRecordingStarted();
          break;
        }
    }
  }
}
function $(t, i) {
  if (!i) throw new Error("[select Media] Callback cannot be null");
  if (d(O, y.content, y.task), !m(n)) {
    const e = {
      errorCode: E.OLD_PLATFORM
    };
    return void i(e, []);
  }
  try {
    f(t);
  } catch (e) {
    return void i(e, []);
  }
  if (!p(t)) {
    const e = {
      errorCode: E.INVALID_ARGUMENTS
    };
    return void i(e, []);
  }
  const o = [t];
  e(k(L, "media.selectMedia"), "selectMedia", o, (e, n, o) => {
    var r, a;
    if (o) return void (C(t) && (null === (a = null === (r = null == t ? void 0 : t.videoProps) || void 0 === r ? void 0 : r.videoController) || void 0 === a || a.notifyEventToApp(o)));
    if (!n) return void i(e, []);
    const s = [];
    for (const e of n) s.push(new x(e));
    i(e, s);
  });
}
function K(t, i) {
  if (!i) throw new Error("[view images] Callback cannot be null");
  if (d(O, y.content, y.task), !m(n)) {
    return void i({
      errorCode: E.OLD_PLATFORM
    });
  }
  if (!T(t)) {
    return void i({
      errorCode: E.INVALID_ARGUMENTS
    });
  }
  const o = [t];
  e(k(L, "media.viewImages"), "viewImages", o, i);
}
function Q(t, i) {
  if (!t) throw new Error("[media.scanBarCode] Callback cannot be null");
  if (d(O, y.content, y.task), s.hostClientType !== I.desktop && s.hostClientType !== I.web && s.hostClientType !== I.rigel && s.hostClientType !== I.teamsRoomsWindows && s.hostClientType !== I.teamsRoomsAndroid && s.hostClientType !== I.teamsPhones && s.hostClientType !== I.teamsDisplays) {
    if (m(a)) {
      if (g(i)) e(k(L, "media.scanBarCode"), "media.scanBarCode", [i], t);else {
        t({
          errorCode: E.INVALID_ARGUMENTS
        }, "");
      }
    } else {
      t({
        errorCode: E.OLD_PLATFORM
      }, "");
    }
  } else {
    t({
      errorCode: E.NOT_SUPPORTED_ON_PLATFORM
    }, "");
  }
}
!function (e) {
  e[e.StartRecording = 1] = "StartRecording", e[e.StopRecording = 2] = "StopRecording";
}(N || (N = {})), function (e) {
  e[e.Photo = 1] = "Photo", e[e.Document = 2] = "Document", e[e.Whiteboard = 3] = "Whiteboard", e[e.BusinessCard = 4] = "BusinessCard";
}(_ || (_ = {})), function (e) {
  e[e.Camera = 1] = "Camera", e[e.Gallery = 2] = "Gallery";
}(B || (B = {})), function (e) {
  e[e.Image = 1] = "Image", e[e.Video = 2] = "Video", e[e.VideoAndImage = 3] = "VideoAndImage", e[e.Audio = 4] = "Audio";
}(V || (V = {})), function (e) {
  e[e.ID = 1] = "ID", e[e.URL = 2] = "URL";
}(j || (j = {})), function (e) {
  e[e.IMAGE = 1] = "IMAGE", e[e.PDF = 2] = "PDF";
}(F || (F = {}));
export { _ as CameraStartMode, U as File, S as FileFormat, F as ImageOutputFormats, j as ImageUriType, x as Media, N as MediaControllerEvent, V as MediaType, B as Source, J as VideoController, G as captureImage, q as hasPermission, H as requestPermission, Q as scanBarCode, $ as selectMedia, K as viewImages };