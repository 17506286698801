import { notifyAppLoadedHelper as i, notifyFailureHelper as n, notifyExpectedFailureHelper as o } from "../internal/appHelpers.js";
import { sendMessageToParent as t } from "../internal/communication.js";
import { getApiVersionTag as p } from "../internal/telemetry.js";
import { Messages as a } from "./app/app.js";
export { ExpectedFailureReason, FailedReason } from "./app/app.js";
import { version as e } from "./version.js";
const r = "v1";
function s() {
  i(p(r, "appInitialization.notifyAppLoaded"));
}
function f() {
  t(p(r, "appInitialization.notifySuccess"), a.Success, [e]);
}
function m(i) {
  n(p(r, "appInitialization.notifyFailure"), i);
}
function c(i) {
  o(p(r, "appInitialization.notifyExpectedFailure"), i);
}
export { a as Messages, s as notifyAppLoaded, c as notifyExpectedFailure, m as notifyFailure, f as notifySuccess };