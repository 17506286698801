import { __rest as t } from "../../../../node_modules/.pnpm/@rollup_plugin-typescript@11.1.6_rollup@4.24.4_tslib@2.6.3_typescript@4.9.5/node_modules/tslib/tslib.es6.js";
import { sendMessageToParent as i } from "../internal/communication.js";
import { botUrlOpenHelper as o, urlOpenHelper as r, updateResizeHelper as e, urlSubmitHelper as n } from "../internal/dialogHelpers.js";
import { ensureInitialized as l } from "../internal/internalAPIs.js";
import { getApiVersionTag as s } from "../internal/telemetry.js";
import { ChildAppWindow as a } from "./appWindow.js";
import { FrameContexts as d, DialogDimension as u } from "./constants.js";
import { runtime as h } from "./runtime.js";
const m = "v1";
function c(t, e) {
  const n = s(m, "tasks.startTask"),
    c = e ? t => {
      var i, o;
      return e(null !== (i = t.err) && void 0 !== i ? i : "", null !== (o = t.result) && void 0 !== o ? o : "");
    } : void 0;
  return void 0 === t.card && void 0 === t.url || t.card ? (l(h, d.content, d.sidePanel, d.meetingStage), i(n, "tasks.startTask", [t], e)) : void 0 !== t.completionBotId ? o(n, function (t) {
    if (void 0 === t.url || void 0 === t.completionBotId) throw new Error(`Both url ${t.url} and completionBotId ${t.completionBotId} are required for bot url dialog. At least one is undefined.`);
    const i = {
      url: t.url,
      size: {
        height: t.height ? t.height : u.Small,
        width: t.width ? t.width : u.Small
      },
      title: t.title,
      fallbackUrl: t.fallbackUrl,
      completionBotId: t.completionBotId
    };
    return i;
  }(t), c) : r(n, function (t) {
    if (void 0 === t.url) throw new Error("url property of taskInfo object can't be undefined");
    const i = {
      url: t.url,
      size: {
        height: t.height ? t.height : u.Small,
        width: t.width ? t.width : u.Small
      },
      title: t.title,
      fallbackUrl: t.fallbackUrl
    };
    return i;
  }(t), c), new a();
}
function p(i) {
  i = g(i);
  const o = t(i, ["width", "height"]);
  if (Object.keys(o).length) throw new Error("resize requires a TaskInfo argument containing only width and height");
  e(s(m, "tasks.updateTask"), i);
}
function f(t, i) {
  n(s(m, "tasks.submitTask"), t, i);
}
function g(t) {
  return t.height = t.height ? t.height : u.Small, t.width = t.width ? t.width : u.Small, t;
}
export { g as getDefaultSizeIfNotProvided, c as startTask, f as submitTask, p as updateTask };