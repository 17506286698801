import { VideoFrameTick as t } from "./videoFrameTick.js";
class s {
  constructor(t, s) {
    this.reportStatisticsResult = s, this.sampleCount = 0, this.distributionBins = new Uint32Array(t);
  }
  processStarts(s, e, i, r) {
    t.tick(), this.suitableForThisSession(s, e, i, r) || this.reportAndResetSession(this.getStatistics(), s, r, e, i), this.start();
  }
  processEnds() {
    const t = performance.now() - this.frameProcessingStartedAt,
      s = Math.floor(Math.max(0, Math.min(this.distributionBins.length - 1, t)));
    this.distributionBins[s] += 1, this.sampleCount += 1;
  }
  getStatistics() {
    return this.currentSession ? {
      effectId: this.currentSession.effectId,
      effectParam: this.currentSession.effectParam,
      frameHeight: this.currentSession.frameHeight,
      frameWidth: this.currentSession.frameWidth,
      duration: performance.now() - this.currentSession.startedAtInMs,
      sampleCount: this.sampleCount,
      distributionBins: this.distributionBins.slice()
    } : null;
  }
  start() {
    this.frameProcessingStartedAt = performance.now();
  }
  suitableForThisSession(t, s, e, i) {
    return this.currentSession && this.currentSession.effectId === t && this.currentSession.effectParam === i && this.currentSession.frameWidth === s && this.currentSession.frameHeight === e;
  }
  reportAndResetSession(s, e, i, r, n) {
    s && this.reportStatisticsResult(s), this.resetCurrentSession(this.getNextTimeout(e, this.currentSession), e, i, r, n), this.timeoutId && t.clearTimeout(this.timeoutId), this.timeoutId = t.setTimeout((() => this.reportAndResetSession(this.getStatistics(), e, i, r, n)).bind(this), this.currentSession.timeoutInMs);
  }
  resetCurrentSession(t, s, e, i, r) {
    this.currentSession = {
      startedAtInMs: performance.now(),
      timeoutInMs: t,
      effectId: s,
      effectParam: e,
      frameWidth: i,
      frameHeight: r
    }, this.sampleCount = 0, this.distributionBins.fill(0);
  }
  getNextTimeout(t, e) {
    return e && e.effectId === t ? Math.min(s.maxSessionTimeoutInMs, 2 * e.timeoutInMs) : s.initialSessionTimeoutInMs;
  }
}
s.initialSessionTimeoutInMs = 1e3, s.maxSessionTimeoutInMs = 3e4;
export { s as VideoPerformanceStatistics };