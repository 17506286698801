import { shouldEventBeRelayedToChild as e, sendMessageEventToChild as t } from "../../internal/childCommunication.js";
import { sendMessageToParent as i } from "../../internal/communication.js";
import { registerHandler as n, registerHandlerHelper as s } from "../../internal/handlers.js";
import { ensureInitialized as o } from "../../internal/internalAPIs.js";
import { configSetValidityStateHelper as r, configSetConfigHelper as f, pagesTelemetryVersionNumber as a } from "../../internal/pagesHelpers.js";
import { getApiVersionTag as c } from "../../internal/telemetry.js";
import { isNullOrUndefined as g } from "../../internal/typeCheckUtilities.js";
import { FrameContexts as u, errorNotSupportedOnPlatform as m } from "../constants.js";
import { runtime as l } from "../runtime.js";
let p, v;
function h() {
  n(c(a, "pages.config.registerSettingsSaveHandler"), "settings.save", E, !1), n(c(a, "pages.config.registerSettingsRemoveHandler"), "settings.remove", F, !1);
}
function d(e) {
  return r(c(a, "pages.config.setValidityState"), e);
}
function y(e) {
  return f(c(a, "pages.config.setConfig"), e);
}
function S(e) {
  N(c(a, "pages.config.registerOnSaveHandler"), e, () => {
    if (!g(e) && !O()) throw m;
  });
}
function N(e, t, n) {
  !g(t) && o(l, u.settings), n && n(), p = t, !g(t) && i(e, "registerHandler", ["save"]);
}
function j(e) {
  w(c(a, "pages.config.registerOnRemoveHandler"), e, () => {
    if (!g(e) && !O()) throw m;
  });
}
function w(e, t, n) {
  !g(t) && o(l, u.remove, u.settings), n && n(), v = t, !g(t) && i(e, "registerHandler", ["remove"]);
}
function E(i) {
  const n = new C(i);
  p ? p(n) : e() ? t("settings.save", [i]) : n.notifySuccess();
}
function H(e) {
  s(c(a, "pages.config.registerChangeConfigHandler"), "changeSettings", e, [u.content], () => {
    if (!O()) throw m;
  });
}
class C {
  constructor(e) {
    this.notified = !1, this.result = e || {};
  }
  notifySuccess() {
    this.ensureNotNotified(), i(c(a, "pages.saveEvent.notifySuccess"), "settings.save.success"), this.notified = !0;
  }
  notifyFailure(e) {
    this.ensureNotNotified(), i(c(a, "pages.saveEvent.notifyFailure"), "settings.save.failure", [e]), this.notified = !0;
  }
  ensureNotNotified() {
    if (this.notified) throw new Error("The SaveEvent may only notify success or failure once.");
  }
}
function F() {
  const i = new T();
  v ? v(i) : e() ? t("settings.remove", []) : i.notifySuccess();
}
class T {
  constructor() {
    this.notified = !1;
  }
  notifySuccess() {
    this.ensureNotNotified(), i(c(a, "pages.removeEvent.notifySuccess"), "settings.remove.success"), this.notified = !0;
  }
  notifyFailure(e) {
    this.ensureNotNotified(), i(c(a, "pages.removeEvent.notifyFailure"), "settings.remove.failure", [e]), this.notified = !0;
  }
  ensureNotNotified() {
    if (this.notified) throw new Error("The removeEventType may only notify success or failure once.");
  }
}
function O() {
  return !(!o(l) || !l.supports.pages) && !!l.supports.pages.config;
}
export { h as initialize, O as isSupported, H as registerChangeConfigHandler, j as registerOnRemoveHandler, w as registerOnRemoveHandlerHelper, S as registerOnSaveHandler, N as registerOnSaveHandlerHelper, y as setConfig, d as setValidityState };