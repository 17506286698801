import { __rest as i } from "../../../../node_modules/.pnpm/@rollup_plugin-typescript@11.1.6_rollup@4.24.4_tslib@2.6.3_typescript@4.9.5/node_modules/tslib/tslib.es6.js";
import { UUID as t } from "../public/uuidObject.js";
const u = t => {
    const {
        uuid: u
      } = t,
      s = i(t, ["uuid"]),
      n = null == u ? void 0 : u.toString();
    return Object.assign(Object.assign({}, s), {
      uuidAsString: n
    });
  },
  s = u => {
    const {
        uuidAsString: s
      } = u,
      n = i(u, ["uuidAsString"]);
    return Object.assign(Object.assign({}, n), {
      uuid: s ? new t(s) : void 0
    });
  },
  n = u => {
    const {
        uuidAsString: s
      } = u,
      n = i(u, ["uuidAsString"]);
    return Object.assign(Object.assign({}, n), {
      uuid: s ? new t(s) : void 0
    });
  },
  r = t => {
    const {
        uuid: u
      } = t,
      s = i(t, ["uuid"]),
      n = null == u ? void 0 : u.toString();
    return Object.assign(Object.assign({}, s), {
      uuidAsString: n
    });
  };
export { s as deserializeMessageRequest, n as deserializeMessageResponse, u as serializeMessageRequest, r as serializeMessageResponse };