import { sendAndHandleSdkError as t } from "../internal/communication.js";
import { peoplePickerRequiredVersion as e } from "../internal/constants.js";
import { ensureInitialized as r, isCurrentSDKVersionAtLeast as o } from "../internal/internalAPIs.js";
import { validatePeoplePickerInput as n } from "../internal/mediaUtil.js";
import { getApiVersionTag as i } from "../internal/telemetry.js";
import { callCallbackWithErrorOrResultFromPromiseAndReturnPromise as s } from "../internal/utils.js";
import { FrameContexts as m, errorNotSupportedOnPlatform as p } from "./constants.js";
import { ErrorCode as l } from "./interfaces.js";
import { runtime as f } from "./runtime.js";
function a(t, e) {
  let o, n;
  r(f, m.content, m.task, m.settings);
  let p = "";
  return "function" == typeof t ? ([o, n] = [t, e], p = i("v1", "people.selectPeople")) : (n = t, p = i("v2", "people.selectPeople")), s(c, o, p, n);
}
function c(r, i) {
  return new Promise(s => {
    if (!o(e)) throw {
      errorCode: l.OLD_PLATFORM
    };
    if (!n(i)) throw {
      errorCode: l.INVALID_ARGUMENTS
    };
    if (!u()) throw p;
    s(t(r, "people.selectPeople", i));
  });
}
function u() {
  return !(!r(f) || !f.supports.people);
}
export { u as isSupported, a as selectPeople };