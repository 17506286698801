import { sendAndHandleSdkError as r } from "../internal/communication.js";
import { ensureInitialized as o } from "../internal/internalAPIs.js";
import { validateScanBarCodeInput as t } from "../internal/mediaUtil.js";
import { getApiVersionTag as n } from "../internal/telemetry.js";
import { FrameContexts as e, errorNotSupportedOnPlatform as s } from "./constants.js";
import { ErrorCode as i, DevicePermission as m } from "./interfaces.js";
import { runtime as a } from "./runtime.js";
const c = "v2";
function f(m) {
  return new Promise(f => {
    if (o(a, e.content, e.task), !d()) throw s;
    if (!t(m)) throw {
      errorCode: i.INVALID_ARGUMENTS
    };
    f(r(n(c, "barCode.scanBarCode"), "media.scanBarCode", m));
  });
}
function p() {
  if (o(a, e.content, e.task), !d()) throw s;
  const t = m.Media;
  return new Promise(o => {
    o(r(n(c, "barCode.hasPermission"), "permissions.has", t));
  });
}
function u() {
  if (o(a, e.content, e.task), !d()) throw s;
  const t = m.Media;
  return new Promise(o => {
    o(r(n(c, "barCode.requestPermission"), "permissions.request", t));
  });
}
function d() {
  return !!(o(a) && a.supports.barCode && a.supports.permissions);
}
export { p as hasPermission, d as isSupported, u as requestPermission, f as scanBarCode };