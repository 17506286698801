import { Buffer as t } from "../../../../_virtual/_polyfill-node.buffer.js";
import { minAdaptiveCardVersion as e } from "../public/constants.js";
import r from "../../../../node_modules/.pnpm/uuid@9.0.1/node_modules/uuid/dist/esm-browser/v4.js";
import n from "../../../../node_modules/.pnpm/uuid@9.0.1/node_modules/uuid/dist/esm-browser/validate.js";
function o(t) {
  return (t, e) => {
    if (!t) throw new Error(e);
  };
}
function i(t, e) {
  if ("string" != typeof t || "string" != typeof e) return NaN;
  const r = t.split("."),
    n = e.split(".");
  function o(t) {
    return /^\d+$/.test(t);
  }
  if (!r.every(o) || !n.every(o)) return NaN;
  for (; r.length < n.length;) r.push("0");
  for (; n.length < r.length;) n.push("0");
  for (let t = 0; t < r.length; ++t) if (Number(r[t]) != Number(n[t])) return Number(r[t]) > Number(n[t]) ? 1 : -1;
  return 0;
}
function c() {
  return r();
}
function u(t) {
  return Object.keys(t).forEach(e => {
    null !== t[e] && void 0 !== t[e] && "object" == typeof t[e] && u(t[e]);
  }), Object.freeze(t);
}
function s(t, e, ...r) {
  const n = t(...r);
  return n.then(t => {
    e && e(void 0, t);
  }).catch(t => {
    e && e(t);
  }), n;
}
function f(t, e, ...r) {
  const n = t(...r);
  return n.then(() => {
    e && e(null);
  }).catch(t => {
    e && e(t);
  }), n;
}
function l(t, e, ...r) {
  const n = t(...r);
  return n.then(t => {
    e && e(null, t);
  }).catch(t => {
    e && e(t, null);
  }), n;
}
function a(t, e, r) {
  return new Promise((n, o) => {
    const i = setTimeout(o, e, r);
    t().then(t => {
      clearTimeout(i), n(t);
    }).catch(t => {
      clearTimeout(i), o(t);
    });
  });
}
function p(t) {
  const e = new URL("https://teams.microsoft.com/l/entity/" + encodeURIComponent(t.appId.toString()) + "/" + encodeURIComponent(t.pageId));
  return t.webUrl && e.searchParams.append("webUrl", t.webUrl.toString()), (t.chatId || t.channelId || t.subPageId) && e.searchParams.append("context", JSON.stringify({
    chatId: t.chatId,
    channelId: t.channelId,
    subEntityId: t.subPageId
  })), e.toString();
}
function d(t) {
  return !(i(`${t.majorVersion}.${t.minorVersion}`, `${e.majorVersion}.${e.minorVersion}`) >= 0);
}
function m(t) {
  return "https:" === t.protocol;
}
function h(e, r) {
  return new Promise((n, o) => {
    if (e || o("MimeType cannot be null or empty."), r || o("Base64 string cannot be null or empty."), e.startsWith("image/")) {
      const t = atob(r),
        o = new Uint8Array(t.length);
      for (let e = 0; e < t.length; e++) o[e] = t.charCodeAt(e);
      n(new Blob([o], {
        type: e
      }));
    }
    const i = t.from(r, "base64").toString();
    n(new Blob([i], {
      type: e
    }));
  });
}
function b(t) {
  return new Promise((e, r) => {
    0 === t.size && r(new Error("Blob cannot be empty."));
    const n = new FileReader();
    n.onloadend = () => {
      n.result ? e(n.result.toString().split(",")[1]) : r(new Error("Failed to read the blob"));
    }, n.onerror = () => {
      r(n.error);
    }, n.readAsDataURL(t);
  });
}
function w() {
  if (g()) throw new Error("window object undefined at SSR check");
  return window;
}
function g() {
  return "undefined" == typeof window;
}
function y(t, e) {
  if (v(t) || !function (t) {
    return t.length < 256 && t.length > 4;
  }(t) || !function (t) {
    for (let e = 0; e < t.length; e++) {
      const r = t.charCodeAt(e);
      if (r < 32 || r > 126) return !1;
    }
    return !0;
  }(t)) throw e || new Error("id is not valid.");
}
function j(t, e) {
  const r = t.toString().toLocaleLowerCase();
  if (v(r)) throw new Error("Invalid Url");
  if (r.length > 2048) throw new Error("Url exceeds the maximum size of 2048 characters");
  if (!m(t)) throw new Error("Url should be a valid https url");
}
function E(t) {
  const e = document.createElement("a");
  return e.href = t, new URL(e.href);
}
function v(t) {
  return new RegExp(`${/<script[^>]*>|&lt;script[^&]*&gt;|%3Cscript[^%]*%3E/gi.source}|${/<\/script[^>]*>|&lt;\/script[^&]*&gt;|%3C\/script[^%]*%3E/gi.source}`, "gi").test(t);
}
function I(t) {
  if (!t) throw new Error("id must not be empty");
  if (!1 === n(t)) throw new Error("id must be a valid UUID");
}
const U = !!performance && "now" in performance;
function O() {
  return U ? performance.now() + performance.timeOrigin : void 0;
}
function S(t, e = 0) {
  if (e > 1e3) return !1;
  if (void 0 === t || "boolean" == typeof t || "number" == typeof t || "bigint" == typeof t || "string" == typeof t || null === t) return !0;
  if (Array.isArray(t)) return t.every(t => S(t, e + 1));
  return !("object" != typeof t || "[object Object]" !== Object.prototype.toString.call(t) || Object.getPrototypeOf(t) !== Object.prototype && null !== Object.getPrototypeOf(t)) && Object.keys(t).every(r => S(t[r], e + 1));
}
export { h as base64ToBlob, s as callCallbackWithErrorOrResultFromPromiseAndReturnPromise, l as callCallbackWithErrorOrResultOrNullFromPromiseAndReturnPromise, f as callCallbackWithSdkErrorFromPromiseAndReturnPromise, i as compareSDKVersions, p as createTeamsAppLink, u as deepFreeze, E as fullyQualifyUrlString, c as generateGUID, b as getBase64StringFromBlob, O as getCurrentTimestamp, o as getGenericOnCompleteHandler, v as hasScriptTags, g as inServerSideRenderingEnvironment, d as isHostAdaptiveCardSchemaVersionUnsupported, S as isPrimitiveOrPlainObject, m as isValidHttpsURL, a as runWithTimeout, w as ssrSafeWindow, y as validateId, j as validateUrl, I as validateUuid };