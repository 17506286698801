import { serializeMessageRequest as e } from "./messageObjects.js";
import { getLogger as s } from "./telemetry.js";
function i(e) {
  return void 0 !== e.uuidAsString ? `${e.uuidAsString} (legacy id: ${e.id})` : void 0 !== e.uuid ? `${e.uuid.toString()} (legacy id: ${e.id})` : `legacy id: ${e.id} (no uuid)`;
}
const t = s("flushMessageQueue");
function u(s, u, o, g) {
  if (s && u && 0 !== o.length) for (; o.length > 0;) {
    const n = o.shift();
    if (n) {
      const o = e(n);
      t("Flushing message %s from %s message queue via postMessage.", i(o), g), s.postMessage(o, u);
    }
  }
}
export { u as flushMessageQueue, i as getMessageIdsAsLogString };